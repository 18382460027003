import React, {Fragment} from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ImageField,
  BooleanField,
  Create,
  Edit,
  SelectInput,
  NumberInput,
  TextInput,
  ReferenceInput,
  SimpleFormIterator,
  ArrayInput,
  AutocompleteInput,
  SelectArrayInput,
  ReferenceArrayInput,
  BooleanInput,
  Filter,
  TabbedForm,
  FormTab,
  CloneButton,
  CardActions, Pagination,
  BulkDeleteButton,
} from 'react-admin';
import BookIcon from '@material-ui/icons/Commute';
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import * as _ from 'lodash';

const MachinePagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />


export const MachineIcon = BookIcon;

export const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

const MachineFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="category" reference="categories" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
      <SelectInput label="classificator1" optionText="name"/>
    </ReferenceInput>
    <ReferenceInput source="brand" reference="brands" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
    <ReferenceInput source="model" reference="models" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </Filter>
);

export const PricingTable = withStyles(useStyles)(({classes, ...props}) => console.log(props) || (
  <Paper className={classes.paper}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <TextInput source={`${props.source}.label`} fullWidth label="Nombre del periodo"/>
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberInput source={`${props.source}.hours`} fullWidth label="Horas"/>
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberInput source={`${props.source}.hourly_price`} fullWidth label="Precio por hora"/>
      </Grid>
    </Grid>
  </Paper>
));
const BulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton {...props} undoable={false}/>
  </Fragment>
);
export const MachineList = props => (
  <List filters={<MachineFilter/>} sort={{field: '_created', order: 'DESC'}} {...props}
        pagination={<MachinePagination/>} perPage={50} bulkActionButtons={<BulkActionButtons/>}>
    <Datagrid rowClick="edit">
      <TextField source="iid" label="#"/>
      <ReferenceField source="category" reference="categories"><TextField source="name"/></ReferenceField>
      <TextField source="name"/>
      <TextField source="desc"/>
      <BooleanField source="referential"/>
      <ReferenceArrayField reference="services" source="services">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ReferenceArrayField>
      <ImageField source="image" title="name"/>
    </Datagrid>
  </List>
);

const EditActions = ({
                       basePath,
                       className,
                       data,
                       hasShow,
                       hasList,
                       resource,
                       ...rest
                     }) => (
  <CardActions className={className} {...rest}>
    <CloneButton
      className="button-clone"
      basePath={basePath}
      record={_.omit(data, ['iid', '_id', '_created', '_updated'])}
    />
  </CardActions>
);

export const MachineEdit = props => (
  <Edit undoable={false} actions={<EditActions/>} {...props}>
    <TabbedForm>
      <FormTab label="general">
        <BooleanInput source="referential"/>
        <TextInput source="name"/>
        <TextInput source="desc"/>
        <TextInput source="capacity"/>
        <NumberInput source="year"/>
        <ReferenceInput source="category" reference="categories">
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="brand" reference="brands">
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="model" reference="models" allowEmpty
                        filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <TextInput source="image"/>
        <NumberInput source="ranking"/>
        <ReferenceArrayInput reference="services" source="services">
          <SelectArrayInput>
            <ChipField source="name"/>
          </SelectArrayInput>
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="precio">
        <NumberInput source="pricing.transfer.base_fare"/>
        <ArrayInput source="pricing.ranges" fullWidth>
          <SimpleFormIterator>
            <PricingTable/>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const MachineCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="general">
        <BooleanInput source="referential"/>
        <ReferenceInput source="category" reference="categories">
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="brand" reference="brands" allowEmpty
                        filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="model" reference="models" allowEmpty
                        filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <TextInput source="desc"/>
        <TextInput source="capacity"/>
        <NumberInput source="year"/>
        <TextInput source="image"/>
        <ReferenceArrayInput reference="services" source="services">
          <SelectArrayInput>
            <ChipField source="name"/>
          </SelectArrayInput>
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="precio">
        <NumberInput source="pricing.transfer.base_fare"/>
        <ArrayInput source="pricing.ranges" fullWidth>
          <SimpleFormIterator>
            <PricingTable/>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
