import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  TextInput,
  SimpleForm,
  SearchInput,
  Filter,
  NumberField,
  DateField,
  NumberInput,
  DateInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  Pagination, ReferenceInput, SingleFieldList, ReferenceField, ArrayField,
} from 'react-admin';
import {cleanRut, ColoredChipField, formatRut, required, validateRUT} from "../utils";
import Business from '@material-ui/icons/Business';
import * as _ from 'lodash';
import MilestonesInput from "./quotes/MilestonesInput";

export const CompanyIcon = Business;

const CompanyFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="RUT" source="rut.$regex" alwaysOn allowEmpty/>
    <SearchInput label="Nombre" source="name.$regex" alwaysOn allowEmpty/>
    <SearchInput label="Actividad" source="economic_activity.name.$regex" alwaysOn allowEmpty/>
    <SearchInput label="Rubro" source="economic_heading.name.$regex" alwaysOn allowEmpty/>
    <SearchInput label="Sub-rubro" source="economic_sub_heading.name.$regex" alwaysOn allowEmpty/>
    <NumberInput label="Tramo según ventas" source="tranche_according_to_sales.$gte" alwaysOn allowEmpty/>
    <ReferenceInput source="milestones._id" reference="company_milestones" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
      <SelectInput label="milestone" optionText="name"/>
    </ReferenceInput>
  </Filter>
);

const CompanyPagination = props => <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />
export const CompanyList = props => (
  <List {...props} filters={<CompanyFilter/>} pagination={<CompanyPagination/>} perPage={100}
        sort={{ field: '_updated', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="rut" />
      <TextField source="name" />
      <TextField label="phone" source="contacts.0.phones.0" />
      <TextField label="email" source="contacts.0.emails.0" />
      <NumberField source="tranche_according_to_sales" />
      <NumberField source="number_of_dependent_workers_reported" />
      <ArrayField source="milestones">
        <SingleFieldList>
          <ReferenceField source="_id" reference="milestones">
            <ColoredChipField source="name"/>
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <TextField source="economic_heading.name" />
      <TextField source="economic_sub_heading.name" />
      <TextField source="economic_activity.name" />
      <TextField source="region.name" />
      <TextField source="province.name" />
      <TextField source="commune.name" />
      <DateField source="current_start_date_of_activities" />
      <NumberField source="negative_own_capital_tranche" />
      <TextField source="regimen_final" />
      <TextField source="taxpayer_subtype" />
      <TextField source="type_of_taxpayer" />
      <NumberField source="year" />
      <TextField source="department" />
      <DateField source="draft_end_date" />
      <TextField source="turn_term_type" />
      <NumberField source="positive_equity_tranche" />
      <TextField source="town" />
      <TextField source="block" />
      <TextField source="city" />
      <TextField source="street" />
      <TextField source="number" />
    </Datagrid>
  </List>
);

export const CompanyEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm defaultValue={{milestones: []}}>
      <TextInput source="rut" />
      <TextInput source="name" />
      <MilestonesInput reference="company_milestones"/>
      <SelectInput source="tranche_according_to_sales" choices={_.range(1, 14).map(id => ({id, name: id}))}/>
      <NumberInput source="number_of_dependent_workers_reported" />
      <DateInput source="current_start_date_of_activities" />
      <ArrayInput source="contacts">
        <SimpleFormIterator>
          <TextInput source="first_name"/>
          <TextInput source="last_name"/>
          <ArrayInput source="phones" defaultValue={['']}>
            <SimpleFormIterator><NumberInput/></SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="emails" defaultValue={['']}>
            <SimpleFormIterator><TextInput parse={value => value.toLowerCase()}/></SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <TextField source="economic_activity.name" />
      <TextField source="economic_heading.name" />
      <TextField source="economic_sub_heading.name" />
      <TextField source="region.name" />
      <TextField source="province.name" />
      <TextField source="commune.name" />
      <NumberInput source="negative_own_capital_tranche" />
      <TextInput source="regimen_final" />
      <TextInput source="taxpayer_subtype" />
      <TextInput source="type_of_taxpayer" />
      <NumberInput source="year" />
      <TextInput source="department" />
      <DateInput source="draft_end_date" />
      <TextInput source="turn_term_type" />
      <NumberInput source="positive_equity_tranche" />
      <TextInput source="town" />
      <TextInput source="block" />
      <TextInput source="city" />
      <TextInput source="street" />
      <TextInput source="number" />
    </SimpleForm>
  </Edit>
);

export const CompanyCreate = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm defaultValue={{milestones: []}}>
      <TextInput source="name"/>
      <TextInput source="rut" validate={[required(), validateRUT()]} parse={cleanRut} format={formatRut}/>
    </SimpleForm>
  </Edit>
);
