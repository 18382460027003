import withStyles from "@material-ui/core/styles/withStyles";
import React, {useEffect, useState} from "react";
import {
  FormTab,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  useNotify,
} from 'react-admin';
import compose from 'recompose/compose';
import * as _ from 'lodash';
import {GET_LIST} from "../../ra-data-eve/src/actions";
import {createStyles} from "@material-ui/core";
import {withDataProvider} from 'react-admin';
import {useForm} from "react-final-form";

function ServicesForm(props) {
  const [services, setServices] = useState(null);
  const form = useForm();
  const {change} = form;
  const notify = useNotify();
  const {record, dataProvider} = props;

  useEffect(() => {
    if (!services)
      dataProvider(GET_LIST, 'services', {filter: {}})
        .then(({data}) => {
          setServices(data);
          const incomingServices = _.get(record, "services", []);
          data
            .filter(({id}) => !incomingServices.map(({_id}) => _id).includes(id))
            .map(({_id}) => incomingServices.push({_id}));
          change('services', incomingServices);
        })
        .catch(error => notify(error.message, 'warning'));
  }, [change, dataProvider, notify, record, services]);

  const servicesDict = _.keyBy(services, '_id');
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {
            ({scopedFormData}) => scopedFormData && (<p>{_.get(servicesDict, `${scopedFormData._id}.name`)}</p>)
          }
        </FormDataConsumer>
        <TextInput source="_id"/>
        <BooleanInput source="available"/>
        <BooleanInput source="required"/>
        <NumberInput source="pricing.0.hourly_price"/>
      </SimpleFormIterator>
    </ArrayInput>
  );
}

const useStyles = (theme) => {
  return createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
};
const enhance = compose(
  withStyles(useStyles),
  withDataProvider,
);
ServicesForm.defaultProps = FormTab.defaultProps;
export default enhance(ServicesForm);
