import * as _ from 'lodash';
import {cloneElement} from "react";
import {validate as rutValidator} from "rut.js";
import React from "react";
import {ChipField} from 'react-admin';
import Chile from './fixtures/chile.json';
import {format} from "rut.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from 'prop-types';

export function diff(a, b) {
  const _diff = (a, b) => {
    var r = {};
    _.each(a, function (v, k) {
      if (JSON.stringify(_.get(b, k)) === JSON.stringify(v)) return;
      r[k] = v;
    });
    return r;
  };
  console.log(_diff(a, b).category);
  return _diff(a, b) || {};
}

export const StringToLabelObject = ({record, children, ...rest}) =>
  cloneElement(children, {
    record: {label: record},
    ...rest,
  });

function recurse(obj, res, current) {
  _.forEach(obj, (value, key) => {
    const newKey = (current ? current + '.' + key : key);  // joined key with dot
    if (value && typeof value === 'object') {
      recurse(value, res, newKey);  // it's a nested object, so do it again
    } else {
      res[newKey] = value;  // it's not an object, so set the property
    }
  });
}

export function toDotNotation(obj) {
  const res = {};
  recurse(obj, res, false);
  return res;
}

export function cleanRut(rut) {
  if (!(typeof rut === 'string'))
    return rut;
  return rut.replace(/[^kK0-9+]+/gi, '').toUpperCase();
}

export function formatRut(rut) {
  const formatted = format(rut);
  return formatted[0] === '-' ? formatted.substr(1, formatted.length) : formatted;
}

export const validateRUT = (message = 'Invalid RUT') => value => !value || rutValidator(value) ? undefined : message;

export const required = (message = 'Required') => value => value ? undefined : message;

export const regions = [
  {
    name: 'Región Metropolitana de Santiago',
    id: 13
  },
  {
    name: 'Tarapacá',
    id: 1
  },
  {
    name: 'Antofagasta',
    id: 2
  },
  {
    name: 'Atacama',
    id: 3
  },
  {
    name: 'Coquimbo',
    id: 4
  },
  {
    name: 'Valparaíso',
    id: 5
  },
  {
    name: 'Región del Libertador Gral. Bernardo O\'Higgins',
    id: 6
  },
  {
    name: 'Región del Maule',
    id: 7
  },
  {
    name: 'Región del Biobío',
    id: 8
  },
  {
    name: 'Región de la Araucanía',
    id: 9
  },
  {
    name: 'Región de Los Lagos',
    id: 10
  },
  {
    name: 'Región Aisén del Gral. Carlos Ibáñez del Campo',
    id: 11
  },
  {
    name: 'Región de Magallanes y de la Antártica Chilena',
    id: 12
  },
  {
    name: 'Región de Los Ríos',
    id: 14
  },
  {
    name: 'Arica y Parinacota',
    id: 15
  },
  {
    name: 'Región de Ñuble',
    id: 16
  }
];

export const colorStyles = {
  red: {background: 'red', color: 'white'},
  green: {background: 'green', color: 'white'},
  blue: {background: 'blue', color: 'white'},
  yellow: {background: 'yellow'},
  dark: {background: 'dark', color: 'white'},
};

export const ColoredChipField = props => (<ChipField style={colorStyles[props.record.color]} {...props} />);
ColoredChipField.defaultProps = ChipField.defaultProps;

export const channels = [
  {name: 'Landing', id: 'landing'},
  {name: 'Whatsapp', id: 'whatsapp'},
  {name: 'Email', id: 'email'},
  {name: 'Phone', id: 'phone'},
  {name: 'Intercom', id: 'intercom'},
  {name: 'Other', id: 'other'},
];

export const communes = Chile.communes;

export function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
