import React from 'react';

import {
  Datagrid,
  Pagination,
  ReferenceField,
  BooleanField,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import CustomList from '../../libs/CustomList';
import {regions} from "../../utils";

const QuotePagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />


const AcceptancesFilter = (props) => (
    <Filter {...props}>
      <ReferenceInput source="category" reference="categories" allowEmpty
                      filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
        <SelectInput label="classificator1" optionText="name"/>
      </ReferenceInput>
      <SelectInput label="Region" source="regions" choices={regions} optionText="name" optionValue="id" allowEmpty alwaysOn/>

    </Filter>
);

export const AcceptanceList = props => (
    <CustomList filters={<AcceptancesFilter/>} pagination={<QuotePagination/>} perPage={50} {...props} >
      <Datagrid >
        <ReferenceField source="category"  reference="categories"><TextField source="name"/></ReferenceField>
        <ReferenceField label="Referential Machine" source="candidate_provider.id_machine"  reference="machines" allowEmpty><TextField source="name"/></ReferenceField>
        <TextField source="region" label="Region"/>
        <BooleanField source="candidate_provider.accepted" label="Acceptance" />
        <ReferenceField  label="Provider" source="candidate_provider.id_provider"  reference="providers"><TextField source="name"/></ReferenceField>
        <DateField source="candidate_provider._updated" label="Confirmation Date" options={{ timeZone: 'UTC'}}  showTime allowEmpty />
        <ReferenceField source="quote_id"  reference="quotes"><TextField source="iid"/></ReferenceField>
        <DateField source="from_date" label="From date of Quote" />
        <DateField source="to_date" label="To date of Quote" />
        <TextField source="candidate_provider.hourly_rate" label="Hourly rate"/>
        <TextField source="candidate_provider.transfer_price" label="Transfer price"/>
        <TextField source="candidate_provider.comments" label="Comments"/>
      </Datagrid>
    </CustomList>
);