import React, {Fragment, useEffect, useState} from 'react';
import {
  Button,
  SimpleForm,
  UPDATE,
  SelectArrayInput,
  ChipField,
  useNotify,
  withDataProvider,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import IconWhatsapp from '@material-ui/icons/Message';
import IconEmail from '@material-ui/icons/Mail';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import * as _ from 'lodash';
import RichTextInput from "ra-input-rich-text";
import * as moment from 'moment';
import 'moment/locale/es';
import * as nunjucks from 'nunjucks';
import {GET_LIST, GET_ONE} from "../../ra-data-eve/src/actions";
import compose from 'recompose/compose';

moment.locale('es');
nunjucks.configure({autoescape: true});

function RequestProvidersButton(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [settings, setSettings] = useState({});
  const [quote, setQuote] = useState({});
  const [providerIds, setProviderIds] = useState(null);
  const [body, setBody] = useState(null);
  const notify = useNotify();
  const {record, dataProvider} = props;

  useEffect(() => {
    if (record._id && (!quote || (quote._updated !== record._updated))) {
      setQuote(_.cloneDeep(record));
      dataProvider(GET_ONE, 'quotes', {
        filter: {
          embedded: {"services": 1, "machines.category": 1},
          projection: {"services": 1, "machines.category": 1, "candidate_providers": 1, "_id": 1}
        },
        id: record._id
      }).then(({data}) => setQuote(_.cloneDeep({...record, ...data})))
        .catch(error => notify(error.message, 'warning'));
    }
  }, [record, dataProvider, quote, notify]);

  const handleClick = event => {
    setShowDialog(true);
    event.stopPropagation();
    dataProvider(GET_LIST, 'settings', {id: quote._id})
      .then(({data}) => setSettings(data[0]))
      .catch(error => notify(error.message, 'warning'));
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const quote_address = () => {
    const addressComponents = _.get(quote, 'client.place.address_components');
    const region = _.find(addressComponents, aC => _.get(aC, 'types.0') === 'administrative_area_level_1');
    const city = _.find(addressComponents, aC => _.get(aC, 'types.0') === 'administrative_area_level_2');
    const commune = _.find(addressComponents, aC => _.get(aC, 'types.0') === 'administrative_area_level_3');
    const places = [commune, city, region].filter(obj => !_.isNil(obj));
    return _.map(places, 'long_name').join(', ');
  };

  const handleWhatsapp = () => {
    if (_.isNil(quote))
      return console.log('try again');
    let command = "";
    let providers = quote.candidate_providers.map(p => p._id);
    if (providerIds && providerIds.length > 0) {
      const providersKeyBy = _.keyBy(providers, '_id');
      providers = providerIds.map(providerId => providerId && providersKeyBy[providerId]).filter(p => !!p);
    }
    providers.forEach(p => {
      const provider_contacts = _.get(p, 'contacts', []);
      const nunjucksEnv = new nunjucks.Environment();
      const machineServices = m => m ? _.get(m, 'requested_services', _.get(quote, 'services')) || [] : '';
      const displayServices = m => (machineServices(m).length > 0 ? ` (con ${machineServices(m).map(s => s.name).join(', ')})` : '').toLowerCase();
      nunjucksEnv.addFilter('displayServices', m => displayServices(m));
      nunjucksEnv.addFilter('map', (a, b) => _.map(a, b.attribute));
      nunjucksEnv.addFilter('formatDate', date => moment(date).format('D [de] MMMM'));
      nunjucksEnv.addFilter('formatAddress', q => _.get(q, 'client.place.address_components') ? quote_address() : _.get(q, 'client.address'));
      let message;
      provider_contacts.forEach(c => {
        const provider_name = _.get(c, 'first_name', ' ').split(" ")[0];
        try {
          message = nunjucksEnv.renderString(body, {
            provider: p,
            quote: quote,
            record: record,
            provider_name
          }).replace(/<strong[^>]*>/g, '*').replace(/<\/strong[^>]*>/g, '*')
            .replace(/<em[^>]*>/g, '_').replace(/<\/em[^>]*>/g, '_')
            .replace(/<[^>]*>/g, '\\n').replace(/\\n[\\n]*/g, '\\n');
        } catch (e) {
          console.error(e);
          message = '';
        }
        const phones = _.get(c, 'phones', [])
        phones.forEach(phone => {
          command += `whatsapp.stackMessage("${phone}", "${message}");\n`;
        })
      })
    });
    console.log(command);
  };

  const handleSubmit = () => {
    const providers = providerIds || quote.candidate_providers.map(p => p._id._id);
    // Dispatch an action letting react-admin know a API call is ongoing
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(UPDATE, 'quotes', {
      data: {
        providers,
        body: _.unescape(body)
      },
      id: quote._id,
      subpath: 'send_email_to_providers'
    })
      .then(() => {
        setShowDialog(false);
        notify('Message was successfully sent to providers.', 'success');
      })
      .catch(error => notify(error.message, 'warning'));
  };

  const providers = _.get(quote, 'candidate_providers', []).map(({_id}) => _id);
  const meessageTemplate = settings.provider_request_message_template;

  return (
    <Fragment>
      <Button onClick={handleClick}>
        <IconWhatsapp/>
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Contact providers"
              onClick={event => event.stopPropagation()}>
        <DialogTitle>Contact providers</DialogTitle>
        <DialogContent>
          <SimpleForm onSubmit={handleSubmit} toolbar={false} record={{body: meessageTemplate}}>
            <SelectArrayInput optionText="name" optionValue="_id" label="Providers" source="providers"
                              choices={providers} allowEmpty alwaysOn
                              onChange={({target: {value}}) => setProviderIds(value)}>
              <ChipField source="name"/>
            </SelectArrayInput>
            <RichTextInput source="body" onChange={body => setBody(body)}/>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" label="ra.action.whatsapp" onClick={handleWhatsapp}>
            <IconWhatsapp/>
          </Button>
          <Button onClick={handleSubmit} label="ra.action.send">
            <IconEmail/>
          </Button>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel/>
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(withDataProvider)(RequestProvidersButton);
