import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  TextInput,
  SimpleForm,
  Create,
  ArrayInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleFormIterator,
  NumberInput,
  FunctionField,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import ChatBubble from '@material-ui/icons/ChatBubble';
import RichTextInput from "ra-input-rich-text";
import * as _ from "lodash";

const actions = [
  {id: 'set_milestone', name: 'Set milestone'},
  {id: 'respond_quote_conversation', name: 'Send message in quote conversation'},
];

export const AutomatedMessageIcon = ChatBubble;

const AutomatedMessageForm = props => (
  <SimpleForm {...props}>
    <TextInput source="name"/>
    <BooleanInput source="active"/>
    <ArrayInput source="conditions">
      <SimpleFormIterator>
        <ReferenceInput
          filterToQuery={searchText => ({$or: [{key_name: {"$regex": searchText}}, {name: {"$regex": searchText}}]})}
          source="milestone" reference="milestones" allowEmpty label="Milestone" alwaysOn>
          <AutocompleteInput optionText="name" placeholder="RUT or name"/>
        </ReferenceInput>
        <BooleanInput source="present"/>
        <NumberInput source="hours" label="Delay (days)" format={h => h && h / 24}
                     parse={d => d && d * 24}/>
      </SimpleFormIterator>
    </ArrayInput>
    <SelectInput source="action" choices={actions} allowEmpty alwaysOn/>
    <FormDataConsumer subscription={{ values: true }}>
      {
        ({formData: {action}}) => {
          if (action === 'set_milestone') {
            return (
              <ReferenceInput
                source="milestone" reference="milestones" allowEmpty alwaysOn
                filterToQuery={searchText => ({$or: [{key_name: {"$regex": searchText}}, {name: {"$regex": searchText}}]})}>
                <AutocompleteInput optionText="name"/>
              </ReferenceInput>
            );
          } else if(action === 'respond_quote_conversation') {
            return (<RichTextInput source="message"/>);
          } else {
            return null;
          }
        }
      }
    </FormDataConsumer>
  </SimpleForm>
);

export const AutomatedMessageList = props => (
  <List {...props} filterDefaultValues={{embedded: {"conditions.milestone": 1}}}>
    <Datagrid rowClick="edit">
      <TextField source="name"/>
      <FunctionField label="Milestones"
                     render={({conditions, action}) => conditions && `${_.lowerCase(action)} ` + conditions.map(({milestone: {key_name}, hours, present}) => `${hours} hours after ${key_name}${!present ? ' has not been triggered' : ''}`).join(" and ")}/>
    </Datagrid>
  </List>
);

export const AutomatedMessageEdit = props => (
  <Edit undoable={false} {...props}>
    <AutomatedMessageForm/>
  </Edit>
);

export const AutomatedMessageCreate = props => (
  <Create undoable={false} {...props}>
    <AutomatedMessageForm/>
  </Create>
);
