import React, {Fragment} from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  TextInput,
  Create,
  NumberInput,
  BooleanInput,
  EmailField,
  Pagination,
  BooleanField,
  Filter,
  SearchInput,
  FunctionField,
  ReferenceField,
  TabbedForm,
  FormTab,
  DateField,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  ReferenceInput,
  AutocompleteInput,
  CardActions,
  BulkDeleteButton,
} from 'react-admin';
import Icon from '@material-ui/icons/People';
import {cleanRut, formatRut, regions, required, validateRUT} from "../utils";
import AcceptEnrollmentButton from "./enrollments/AcceptEnrollmentButton";

export const UserIcon = Icon;

const UserForm = props => (
  <TabbedForm {...props}>
    <FormTab label="User">
      {props.create ? <TextInput source="id"/> : <TextField source="id"/>}
      <TextInput source="first_name"/>
      <TextInput source="last_name"/>
      <TextInput source="email"/>
      <NumberInput source="phone"/>
      <TextInput source="company.rut" validate={[required(), validateRUT()]} parse={cleanRut} format={formatRut}/>
      <TextInput source="company.name"/>
      <ReferenceInput source="company.id" label="Company" reference="companies" allowEmpty
                      filterToQuery={searchText => ({$or: [{rut: {"$regex": searchText}}, {name: {"$regex": searchText}}]})}
                      alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput source="provider.id" label="Provider" reference="providers" allowEmpty
                      filterToQuery={searchText => ({$or: [{rut: {"$regex": searchText}}, {name: {"$regex": searchText}}]})}
                      alwaysOn>
        <AutocompleteInput optionText="name" placeholder="RUT or name"/>
      </ReferenceInput>
      <BooleanInput source="roles.provider"/>
      <BooleanInput source="roles.consumer"/>
    </FormTab>
    {props?.record?.provider?.enrollment && <FormTab label="Provider Enrollment">
      <TextInput source="provider.enrollment.name"/>
      <TextInput source="provider.enrollment.rut" validate={[required(), validateRUT()]} parse={cleanRut}
                 format={formatRut}/>
      <BooleanInput source="provider.enrollment.approved"/>
      <ReferenceArrayInput reference="categories" source="provider.enrollment.categories">
        <SelectArrayInput>
          <ChipField source="provider.enrollment.name"/>
        </SelectArrayInput>
      </ReferenceArrayInput>
      <TextInput source="provider.enrollment.other_categories"/>
      <SelectArrayInput choices={regions} optionText="name" optionValue="id" source="provider.enrollment.regions"
                        format={v => v && v.map(r => r.id)} parse={r_ids => regions.filter(r => r_ids.includes(r.id))}/>
      <DateField source="provider.enrollment._created"/>
      <DateField source="provider.enrollment._updated"/>
    </FormTab>
    }
  </TabbedForm>
);

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="email" label="Email" alwaysOn allowEmpty/>
    <BooleanInput source="roles.provider" label="Provider" alwaysOn/>
    <BooleanInput source="roles.consumer" label="Consumer" alwaysOn/>
    <BooleanInput source="roles.admin" label="Admin" alwaysOn/>
  </Filter>
);
const BulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton {...props} undoable={false}/>
  </Fragment>
);
export const UserList = props => (
  <List perPage={50} filterDefaultValues={{}} filters={<UserFilter/>} sort={{field: '_created', order: 'DESC'}}
        pagination={<Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />} {...props}
        bulkActionButtons={<BulkActionButtons/>}>
    <Datagrid rowClick="edit">
      <FunctionField label="Name" render={({first_name, last_name}) => `${first_name} ${last_name}`}/>
      <EmailField source="email"/>
      <TextField source="phone"/>
      <BooleanField source="roles.provider" label="provider"/>
      <BooleanField source="roles.consumer" label="consumer"/>
      <ReferenceField source="provider.id" reference="providers"><TextField source="name"/></ReferenceField>
      <DateField source="_created"/>
      <DateField source="_updated"/>
    </Datagrid>
  </List>
);

const EditActions = (props) => (
  <CardActions {...props}>
    <AcceptEnrollmentButton record={props.data} {...props}></AcceptEnrollmentButton>
  </CardActions>
);
export const UserEdit = props => (
  <Edit undoable={false} actions={<EditActions/>} {...props}>
    <UserForm/>
  </Edit>
);

export const UserCreate = props => (
  <Create undoable={false} {...props}>
    <UserForm create={true}/>
  </Create>
);

