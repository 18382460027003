import React, {useEffect, useState} from 'react';
import {Admin, Resource, Login} from 'react-admin';
import firebase from 'firebase';
import {MachineList, MachineEdit, MachineCreate, MachineIcon} from './resources/machines';
import {QuoteList, QuoteEdit, QuoteCreate, QuoteIcon} from './resources/quotes';
import {BrandList, BrandEdit, BrandCreate, BrandIcon} from './resources/brands';
import {ModelList, ModelEdit, ModelCreate, ModelIcon} from './resources/models';
import {CategoryList, CategoryEdit, CategoryCreate, CategoryIcon} from './resources/categories';
import {ServiceList, ServiceEdit, ServiceCreate, ServiceIcon} from './resources/services';
import {AutomatedMessageList, AutomatedMessageEdit, AutomatedMessageCreate, AutomatedMessageIcon} from './resources/automated_messages';
import {CompanyList, CompanyEdit, CompanyIcon, CompanyCreate} from './resources/companies';
import {AssetList, AssetEdit, AssetCreate, AssetIcon} from './resources/assets';
import {SettingList, SettingEdit, SettingIcon} from './resources/settings';
import {MilestoneList, MilestoneEdit, MilestoneIcon, MilestoneCreate} from './resources/milestones';
import {CompanyMilestoneList, CompanyMilestoneEdit, CompanyMilestoneIcon, CompanyMilestoneCreate} from './resources/company_milestones';
import {ProviderList, ProviderEdit, ProviderCreate, ProviderIcon} from "./resources/providers";
import {UserList, UserEdit, UserCreate, UserIcon} from "./resources/users";
import {AcceptanceList} from "./resources/quotes/acceptances";
import {Dashboard} from "./resources/dashboard";
import {Layout} from 'react-admin';
import MyMenu from './Menu';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DataProvider from "./dataProvider";
import {JssProvider} from "react-jss";
import {FirebaseAuthProvider} from "react-admin-firebase";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG.replace(/'/g, '"'));
const firebaseApp = firebase.initializeApp(firebaseConfig);
const authProvider = FirebaseAuthProvider(firebaseConfig, {persistence: 'local', app: firebaseApp});
const MyLayout = (props) => <Layout {...props} menu={MyMenu}/>;

export default function App() {
  const [token, setToken] = useState(undefined);

  useEffect(() => {
    if (token === undefined) {
      firebase.auth().onIdTokenChanged(
        user => {
          if (!user) {
            localStorage.setItem('FIREBASE_TOKEN', null);
            setToken(null);
          } else {
            user.getIdToken().then(token => {
              setToken(!token ? null : token);
              localStorage.setItem('FIREBASE_TOKEN', token);
            });
          }
        }
      );
    }
  }, [token]);

  if (token === undefined) return null;

  return (
    <JssProvider>
      <Admin appLayout={MyLayout} dataProvider={DataProvider} loginPage={Login} dashboard={Dashboard}
             authProvider={authProvider}>
        <Resource name="quotes" list={QuoteList} edit={QuoteEdit} create={QuoteCreate} icon={QuoteIcon}/>
        <Resource name="providers" list={ProviderList} edit={ProviderEdit} create={ProviderCreate} icon={ProviderIcon}/>
        <Resource name="machines" list={MachineList} edit={MachineEdit} create={MachineCreate} icon={MachineIcon}/>
        <Resource name="automated_messages" list={AutomatedMessageList} edit={AutomatedMessageEdit}
                  create={AutomatedMessageCreate} icon={AutomatedMessageIcon}/>
        <Resource name="assets" list={AssetList} edit={AssetEdit} create={AssetCreate} icon={AssetIcon}/>
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon}/>
        <Resource name="companies" list={CompanyList} edit={CompanyEdit} create={CompanyCreate} icon={CompanyIcon}/>
        <Resource name="brands" list={BrandList} edit={BrandEdit} create={BrandCreate} icon={BrandIcon}/>
        <Resource name="models" list={ModelList} edit={ModelEdit} create={ModelCreate} icon={ModelIcon}/>
        <Resource name="categories" list={CategoryList} edit={CategoryEdit} create={CategoryCreate} icon={CategoryIcon}/>
        <Resource name="services" list={ServiceList} edit={ServiceEdit} create={ServiceCreate} icon={ServiceIcon}/>
        <Resource name="quote_milestones" list={MilestoneList} edit={MilestoneEdit} create={MilestoneCreate}
                  icon={MilestoneIcon} options={{label: 'Quote Milestones'}}/>
        <Resource name="company_milestones" list={CompanyMilestoneList} edit={CompanyMilestoneEdit}
                  create={CompanyMilestoneCreate} icon={CompanyMilestoneIcon} options={{label: 'Company Milestones'}}/>
        <Resource name="settings" list={SettingList} edit={SettingEdit} icon={SettingIcon}/>
        <Resource name="provider_responses" list={AcceptanceList} icon={DoneAllIcon}/>
        <Resource name="milestones" list={MilestoneList} edit={MilestoneEdit} create={MilestoneCreate}
                  icon={MilestoneIcon} options={{label: 'Milestones'}}/>
      </Admin>
    </JssProvider>
  );
}
