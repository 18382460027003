/*eslint-disable */
import React, {Children, cloneElement, Component, isValidElement,} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import get from 'lodash/get';
import FormHelperText from '@material-ui/core/FormHelperText';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {translate} from 'ra-core';
import {FormInput} from 'react-admin';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
      ({__proto__: []} instanceof Array && function (d, b) {
        d.__proto__ = b;
      }) ||
      function (d, b) {
        for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
      };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();


var styles = function (theme) {
  var _a, _b;
  return createStyles({
    root: {
      padding: 0,
      marginBottom: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    line: (_a = {
      display: 'flex',
      listStyleType: 'none',
      borderBottom: "solid 0px " + theme.palette.divider
    },
      _a[theme.breakpoints.down('xs')] = {display: 'block'},
      _a['&.fade-enter'] = {
        opacity: 0.01,
        transform: 'translateX(100vw)',
      },
      _a['&.fade-enter-active'] = {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'all 500ms ease-in',
      },
      _a['&.fade-exit'] = {
        opacity: 1,
        transform: 'translateX(0)',
      },
      _a['&.fade-exit-active'] = {
        opacity: 0.01,
        transform: 'translateX(100vw)',
        transition: 'all 500ms ease-in',
      },
      _a),
    index: (_b = {
      width: '3em',
      paddingTop: '0'
    },
      _b[theme.breakpoints.down('sm')] = {display: 'none'},
      _b),
    form: {flex: 2},
    action: {
      paddingTop: '0.5em',
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
  });
};
var GenericFormIterator = /** @class */ (function (_super) {
  __extends(GenericFormIterator, _super);

  function GenericFormIterator(props) {
    var _this = _super.call(this, props) || this;
    _this.removeField = function (index) {
      return function () {
        var fields = _this.props.fields;
        _this.ids.splice(index, 1);
        fields.remove(index);
      };
    };
    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determing if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.
    _this.disableRemoveField = function (record, disableRemove) {
      if (typeof disableRemove === 'boolean') {
        return disableRemove;
      }
      return disableRemove && disableRemove(record);
    };
    _this.addField = function () {
      var fields = _this.props.fields;
      _this.ids.push(_this.nextId++);
      fields.push({});
    };
    // we need a unique id for each field for a proper enter/exit animation
    // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
    // so we keep an internal map between the field position and an autoincrement id
    _this.nextId = props.fields.length
      ? props.fields.length
      : props.defaultValue
        ? props.defaultValue.length
        : 0;
    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    _this.ids = _this.nextId > 0 ? Array.from(Array(_this.nextId).keys()) : [];
    return _this;
  }

  GenericFormIterator.prototype.render = function () {
    var _this = this;
    var _a = this.props, basePath = _a.basePath, _b = _a.classes, classes = _b === void 0 ? {} : _b,
      children = _a.children, fields = _a.fields, _c = _a.meta, error = _c.error, submitFailed = _c.submitFailed,
      record = _a.record, resource = _a.resource, source = _a.source, translate = _a.translate,
      disableAdd = _a.disableAdd, disableRemove = _a.disableRemove;
    var records = get(record, source);
    return fields ? (React.createElement("ul", {className: classes.root},
      submitFailed && error && (React.createElement(FormHelperText, {error: true}, error)),
      React.createElement(TransitionGroup, null, fields.map(function (member, index) {
        return (React.createElement(CSSTransition, {key: _this.ids[index], timeout: 500, classNames: "fade"},
          React.createElement("li", {className: classes.line},
            React.createElement("section", {className: classes.form}, Children.map(children, function (input, index2) {
              return isValidElement(input) ? (React.createElement(FormInput, {
                basePath: input.props.basePath ||
                  basePath, input: cloneElement(input, {
                  source: input.props.source
                    ? member + "." + input.props.source
                    : member,
                  index: input.props.source
                    ? undefined
                    : index2,
                  label: input.props.label ||
                    input.props.source,
                }), record: (records &&
                  records[index]) ||
                  {}, resource: resource
              })) : null;
            })),
            )));
      })),
      !disableAdd && (React.createElement("li", {className: classes.line},
      )))) : null;
  };
  return GenericFormIterator;
}(Component));
export {GenericFormIterator};
GenericFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};
GenericFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
export default compose(translate, withStyles(styles))(GenericFormIterator);
/*eslint-enable */