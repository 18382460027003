import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {createStyles} from "@material-ui/core";
import * as _ from 'lodash';
import {
  fetchEnd,
  fetchStart,
  showNotification,
} from 'react-admin';

class DenseTable extends Component {
  static useStyles(theme) {
    return createStyles({
      root: {
        width: '1300px',
      },
      paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
      table: {},
    })
  }

  render() {
    const {classes, data_sources} = this.props;
    if (!data_sources || !data_sources.yapo)
      return (<p>No hay post de yapo asociado a este asset</p>);
    const yapo = data_sources.yapo;
    const phone_url = "http://www.yapo.cl".concat(_.get(yapo,'phone_img_url',"No hay imagen de número asociado"));
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Table className={classes.table} aria-label="a dense table" lavel>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                    <b>Id del Usuario</b>
                  </TableCell>
                  <TableCell align="left">
                      {_.get(yapo,'_id',"No hay id asociada")}
                  </TableCell>
                </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Id del Usuario</b>
                      </TableCell>
                      <TableCell align="left">
                          {_.get(yapo,'user_name',"No hay nombre asociado")}
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Imagen del Número</b>
                      </TableCell>
                      <TableCell align="left">
                          <img src={phone_url} alt="No hay imagen del número asociada" height={15} width={114}/>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Número</b>
                      </TableCell>
                      <TableCell align="left">
                          {_.get(yapo,'phone_number',"No hay número asociado")}
                      </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
    )
  };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

const enhance = compose(
  withStyles(DenseTable.useStyles),
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(DenseTable);
