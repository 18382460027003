import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  UPDATE,
} from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/es';
import {formatRut} from "../../utils";
import compose from 'recompose/compose';
import {withDataProvider} from 'react-admin';

moment.locale('es');

class AcceptEnrollmentButton extends Component {
  state = {
    showDialog: false
  };

  handleClick = event => {
    this.setState({showDialog: true});
    event.stopPropagation();
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  handleSaveClick = () => {
    const {fetchStart, fetchEnd, showNotification, dataProvider, record} = this.props;
    fetchStart();
    dataProvider(UPDATE, 'users', {
      data: {},
      id: record.id,
      subpath: 'accept_enrollment'
    })
      .then(({data}) => {
        showNotification('Postulación aceptada.', 'success');
      })
      .catch(error => showNotification(error.message, 'warning'))
      .finally(() => {
        this.setState({showDialog: false});
        fetchEnd();
      });
  };

  render() {
    const {showDialog} = this.state;
    const {record} = this.props;
    if (!record || _.get(record, 'provider.id', false))
      return null;
    return (
      <Fragment>
        <Button onClick={this.handleClick} label={"Aceptar Enrollment"}>
          <CheckIcon/>
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="accept enrollment"
                onClick={event => event.stopPropagation()}>
          <DialogTitle>Aceptar proveedor</DialogTitle>
          <DialogContent>
            ¿Estás seguro de que quieres permitir
            que {_.get(record, 'first_name')} {_.get(record, 'last_name')}
            administre los datos del proveedor con rut {formatRut(_.get(record, 'provider.enrollment.rut'))}?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSaveClick} label="ra.action.accept">
              <CheckIcon/>
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <CancelIcon/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDataProvider
)(AcceptEnrollmentButton);
