import React, {createElement} from 'react';
import {connect} from 'react-redux';
import {
  MenuItemLink,
  getResources,
  GET_LIST,
  useNotify,
} from 'react-admin';
import {withRouter} from 'react-router-dom';
import BookIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import compose from 'recompose/compose';
import {withDataProvider} from 'react-admin';
import * as _ from 'lodash';

const MyMenu = (props) => {
  const {resources, onMenuClick, dataProvider} = props;
  const [settingId, setSettingId] = React.useState(null);
  const notify = useNotify();
  React.useEffect(() => {
    if (!settingId) dataProvider(GET_LIST, 'settings', {})
      .then(({data}) => setSettingId(data[0].id))
      .catch(error => notify(error.message, 'warning'));
  }, [dataProvider, notify, settingId]);
  return (
    <div>
      <MenuItemLink leftIcon={createElement(DashboardIcon)} to="/" primaryText="Dashboard"
                    onClick={onMenuClick}/>
      {resources.filter(r => r.name !== 'settings').map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={_.get(resource, 'options.label', resource.name)}
          leftIcon={createElement(resource.icon)}
          onClick={onMenuClick}
        />
      ))}
      <MenuItemLink leftIcon={createElement(BookIcon)} to={`/settings/${settingId}`} primaryText="Settings"
                    onClick={onMenuClick}/>
    </div>
  );
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default compose(
  connect(mapStateToProps),
  withDataProvider,
  withRouter
)(MyMenu);
