import React, {Component} from 'react';
import {Button} from 'react-admin';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {
  fetchEnd,
  fetchStart,
  showNotification,
} from 'react-admin';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import * as moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

class ClearFilterButton extends Component {
  render() {
    return (
        <NavLink to={{
          pathname: `/assets`,
          search:  encodeURI(`filter=""&order=DESC&page=1&perPage=50&sort=_updated`),
        }} onClick={event=>event.stopPropagation()} activeStyle={{
          textDecoration:"none"
        }}>
          <Button label="Clear Filters" >
          <LayersClearIcon/>
          </Button>
        </NavLink>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  ClearFilterButton
);
