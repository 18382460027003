import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import {
  Link,
  FormTab,
  ArrayField,
  SingleFieldList,
  ChipField, useNotify,
} from 'react-admin';
import Button from "@material-ui/core/Button";
import compose from 'recompose/compose';
import * as _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {GET_LIST} from "../../ra-data-eve/src/actions";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {createStyles} from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import {withDataProvider} from 'react-admin';

function AssetsTable(props) {
  const [assets, setAssets] = useState(undefined);
  const notify = useNotify();
  const {dataProvider, record: {_id}} = props;

  useEffect(() => {
    const getAssets = () => {
      const filters = {
        filter: {provider: _id, embedded: {"brand": 1, "model": 1, "category": 1}},
        pagination: {page: 0, perPage: 100}
      };
      dataProvider(GET_LIST, 'assets', filters)
        .then(({data}) => setAssets(data))
        .catch(error => notify(error.message, 'warning'))
    };
    if (!assets && !!_id) getAssets();
  }, [_id, dataProvider, notify, assets]);

  const {classes} = props;
  return (
    <div className={classes.root}>
      <Grid container justify="flex-end" className={classes.control}>
        <Link to={{pathname: '/assets/create', state: {record: {provider: _id}}}}
              className={classes.addButton}>
          <Button variant="contained" color="primary" aria-label="show provider's assets">
            <AddIcon/>
          </Button>
        </Link>
        <Link to={{
          pathname: `/assets`,
          search: `filter={"provider":"${_id}"}`,
        }}>
          <Button variant="contained" color="primary" aria-label="show provider's assets">
            <ListIcon/>
          </Button>
        </Link>
      </Grid>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Asset</TableCell>
              <TableCell align="center">Units</TableCell>
              <TableCell align="center">Operator</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Fuel</TableCell>
              <TableCell align="center">Transport</TableCell>
              <TableCell align="center">Price (hour)</TableCell>
              <TableCell align="center">Regions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets && assets.map(asset => (
              <TableRow
                key={asset.brand ? `${_.get(asset, 'brand.name')} ${_.get(asset, 'model.name')}` : _.get(asset, 'category.name')}>
                <TableCell align="left">
                  <Link to={{pathname: `/assets/${asset._id}`}}>
                    {_.get(asset, 'category.name', _.get(asset, 'category_name'))}
                  </Link>
                </TableCell>
                <TableCell
                  align="left">{_.get(asset, 'brand.name', _.get(asset, 'brand_name'))} {_.get(asset, 'model.name', _.get(asset, 'model_name'))}</TableCell>
                <TableCell align="center">{asset.units}</TableCell>
                <TableCell align="center">
                  {_.find(asset.services, obj => obj._id === "5d3deba6f47fbfd6bf6d13be") ? (
                    <CheckCircleIcon color="primary"/>) : (<CancelIcon color="error"/>)}
                </TableCell>
                <TableCell align="center">
                  {asset.active ? (<CheckCircleIcon color="primary"/>) : (<CancelIcon color="error"/>)}
                </TableCell>
                <TableCell align="center">
                  {_.find(asset.services, obj => obj._id === "5d3deba6f47fbfd6bf6d13bf") ? (
                    <CheckCircleIcon color="primary"/>) : (<CancelIcon color="error"/>)}
                </TableCell>
                <TableCell align="center">
                  {_.find(asset.services, obj => obj._id === "5d3deba6f47fbfd6bf6d13c0") ? (
                    <CheckCircleIcon color="primary"/>) : (<CancelIcon color="error"/>)}
                </TableCell>
                <TableCell align="center">
                  {_.get(asset, 'pricing.ranges.0.hourly_price')}
                </TableCell>
                <TableCell align="left"><ArrayField {...props} source="regions">
                  <SingleFieldList><ChipField source="name"/></SingleFieldList></ArrayField>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

const useStyles = (theme) => {
  return createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    control: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    addButton: {
      paddingRight: theme.spacing(2),
    },
    paper: {
      width: '100%',
      overflowX: 'auto',
      marginBottom: theme.spacing(2),
    },
  })
};
const enhance = compose(
  withStyles(useStyles),
  withDataProvider,
);
AssetsTable.defaultProps = FormTab.defaultProps;
export default enhance(AssetsTable);
