import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import compose from 'recompose/compose';
import {createStyles} from "@material-ui/core";
import * as _ from 'lodash';
import {GET_LIST, withDataProvider} from "ra-core";
import {GET_MANY} from "../../ra-data-eve/src/actions";

const styles = function (theme) {
  return createStyles({
    root: {
      width: '100%',
    },
    paper: {
      marginTop: theme.spacing(3),
      width: '100%',
      overflowX: 'auto',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  })
};

function ProviderTable(props) {
  const {classes, record, quote, dataProvider} = props;
  const quoteMachines = _.get(quote, 'machines', []);
  const recordMachines = _.cloneDeep(_.get(record, 'machines', []));
  const [providerId, setProviderId] = useState(false);
  const [machines, setMachines] = useState();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    if (!categories)
      dataProvider(GET_LIST, 'categories', {})
        .then(({data}) => setCategories(_.keyBy(data, '_id')));
  }, [categories, dataProvider]);

  useEffect(() => {
    if (!providerId || (props.providerId && providerId !== props.providerId)) {
      setProviderId(props.providerId);
      const clonedMachines = _.cloneDeep(
        recordMachines.map(m => ({...m, data: _.find(quoteMachines, ({_id}) => _id === m._id) || undefined}))
      );
      const machineWithNoDataIds = clonedMachines.filter(m => m.data === undefined).map(({_id}) => _id);
      setMachines(_.cloneDeep(clonedMachines));
      if (machineWithNoDataIds.length > 0) {
        dataProvider(GET_MANY, 'machines', {ids: machineWithNoDataIds, filter: {projection: {name: 1, category: 1}}})
          .then(({data}) => {
            const machinedById = _.keyBy(clonedMachines, '_id');
            data.map(m => _.set(machinedById[m._id], 'data', m));
            setMachines(clonedMachines);
          });
      }
    }
  }, [dataProvider, machines, props.providerId, providerId, quoteMachines, recordMachines]);
  if (!machines || machines.length === 0)
    return (<p>El proveedor no seleccionó máquinas</p>);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Máquina</TableCell>
              <TableCell align="right">Precio por hora (sin iva)</TableCell>
              <TableCell align="right">Mínimo de horas diarias</TableCell>
              <TableCell align="right">Precio del traslado (cada viaje, sin iva)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {machines.map((machine, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {_.get(machine, 'data.name')} ({_.get(categories, `${_.get(machine, 'data.category')}.name`)})
                </TableCell>
                <TableCell align="right">{machine.terms.hourly_rate}</TableCell>
                <TableCell align="right">{machine.terms.min_daily_hours}</TableCell>
                <TableCell align="right">{machine.terms.transfer_price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

export default compose(withStyles(styles), withDataProvider)(ProviderTable);
