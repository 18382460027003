import PythonEveDataProvider from "./ra-data-eve/src";
import {FirebaseDataProvider} from "react-admin-firebase";

const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG.replace(/'/g, '"'));
export default (type, resource, params) => {
  if (resource === 'users' && params.subpath !== 'accept_enrollment')
    return FirebaseDataProvider(config, {})(type, resource, params);
  else
    return PythonEveDataProvider(type, resource, params);
};
