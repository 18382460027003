import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import {
  FormTab,
  SearchInput,
  SelectInput,
  NumberInput,
  SelectArrayInput, useNotify
} from 'react-admin';
import compose from 'recompose/compose';
import * as _ from 'lodash';
import {GET_ONE} from "../../ra-data-eve/src/actions";
import {createStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import {withDataProvider} from 'react-admin';
import {useForm} from "react-final-form";

function AssetsCategoryFilters(props) {
  const [category, setCategory] = useState(null);
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const form = useForm();
  const {change, batch} = form;
  const {filterValues, dataProvider, classes} = props;
  const categoryId = _.get(filterValues, 'category', null);
  const notify = useNotify();

  useEffect(() => {
    if (categoryId && _.get(category, 'id') !== categoryId) {
      dataProvider(GET_ONE, 'categories', {id: categoryId})
        .then(({data}) => setCategory(data))
        .catch(error => notify(error.message, 'warning'))
    }
  }, [category, categoryId, dataProvider, notify]);


  useEffect(() => {
    if (categoryId) {
      batch(() => {
        change('quote_filter', []);
        change('attributes', {});
        change('$and', []);
      });
    } else {
      batch(() => {
        change('quote_filter', undefined);
        change('attributes', undefined);
        change('$and.1.$and', undefined);
      });
      setCategory(null);
      setSelectedAccessories([])
    }

  }, [batch, categoryId, change]);

  const handleChange = event => {
    setSelectedAccessories(event.target.value);
    const accessories = _.get(category, 'accessories', []);
    batch(() => {
      change('$and.1.$and', [{}]);
      accessories.filter(({id}) => selectedAccessories.includes(id)).map((acc, index) => change(`$and.1.$and.${index}.accessories.$elemMatch.id`, acc.id));
      accessories.filter(({id}) => selectedAccessories.includes(id)).map((acc, index) => change(`$and.1.$and.${index}.accessories.$elemMatch.available`, true));
    })
  };

  const attributes = _.get(category, 'attributes', []);
  const accessories = _.get(category, 'accessories', []);

  const format_range = selected => {
    if (selected === undefined || selected === []) {
      return
    }
    if (typeof selected === "object") {
      return Object.values(selected);
    }
    if (selected.length === 1) {
      return selected;
    }
    return [Math.min.apply(Math, selected), Math.max.apply(Math, selected)];
  };

  const parse_range = p => {
    if (p.length === 0) {
      return {}
    }
    if (p.length === 1) {
      return {"$gte": p[0]};
    }
    if (p[0] <= p[1]) return {"$gte": p[0], "$lte": p[1]};
    return {"$gte": p[1], "$lte": p[0]}
  };

  return category && (
    <Grid {...props}>
      {attributes.map(attr => {
        if (!attr.options || attr.options.length === 0) {
          if (attr.type === 'decimal' || attr.type === 'integer')
            return (<NumberInput label={attr.label} source={`attributes.${attr.id}.$gte`}/>);
          else if (attr.type === 'bool')
            return (<SelectInput choices={[{id: true, name: 'True'}, {id: false, name: 'False'}]} label={attr.label}
                                 source={`attributes.${attr.id}`} allowEmpty alwaysOn/>);
          else
            return (<SearchInput label={attr.label} source={`attributes.${attr.id}.$regex`}/>);
        } else {
          if (attr.type === 'decimal' || attr.type === 'integer')
            return (
              <SelectArrayInput label={attr.label} optionText={"label"} optionValue={"value"} choices={attr.options}
                                format={format_range} parse={parse_range} source={`attributes.${attr.id}`}
                                style={{width: 140}} allowEmpty/>);
          else if (attr.type === 'bool')
            return (<SelectInput label={attr.label} optionText={"label"} optionValue={"value"} choices={attr.options}
                                 source={`attributes.${attr.id}`} allowEmpty/>);
          else
            return (<SelectInput label={attr.label} optionText={"label"} optionValue={"value"} choices={attr.options}
                                 source={`attributes.${attr.id}.$regex`} allowEmpty/>);
        }
      })}
      <FormControl className={classes.formControl}>
        <InputLabel>Accessories</InputLabel>
        <Select multiple value={selectedAccessories} onChange={handleChange}
                input={<Input id="select-multiple-chip"/>}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {accessories.filter(({id}) => selected.includes(id)).map(({id, name}) => (
                      <Chip key={id} label={name} className={classes.chip}/>
                    ))}
                  </div>
                )}>
          {accessories.map(({id, name}) => (<MenuItem value={id} name={name}>{name}</MenuItem>))}
        </Select>
      </FormControl>
    </Grid>
  )
}

const useStyles = (theme) => {
  return createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  })
};

const enhance = compose(
  withStyles(useStyles),
  withDataProvider,
);
AssetsCategoryFilters.defaultProps = FormTab.defaultProps;
export default enhance(AssetsCategoryFilters);
