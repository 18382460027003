import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {createStyles} from "@material-ui/core";
import * as _ from 'lodash';
import {GET_LIST} from "../../ra-data-eve/src/actions";
import {
  fetchEnd,
  fetchStart,
  showNotification,
} from 'react-admin';
import {withDataProvider} from 'react-admin';

class DenseTable extends Component {
  static useStyles(theme) {
    return createStyles({
      root: {
        width: '1300px',
      },
      paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
      table: {},
    })
  }

  constructor(props) {
    super(props);
    this.state = {categories:{}};
    this.getCategories();
  }

    getCategories() {
    const {dataProvider} = this.props;
      const filters = {
          filter: {},
      };
      dataProvider(GET_LIST, 'categories', filters)
          .then(({data}) => {
              this.setState({categories: data});
          })
          .catch(error => {
              const issues = _.get(error, 'body._issues', {});
              showNotification(Object.keys(issues).map(key => `${key} ${issues[key]}`).join(', ') + '.', 'error');
          })
          .finally(() => fetchEnd());
        }

  render() {
    const {classes, data_sources} = this.props;
    if (!data_sources || !data_sources.yapo)
      return (<p>No hay post de yapo asociado a este asset</p>);
    const yapo = data_sources.yapo;
    const categories = yapo.categories.map(id => _.find(this.state.categories, category => (category.id===id)))
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Table className={classes.table} aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                    <b>Id del post</b>
                  </TableCell>
                  <TableCell align="left">
                      {_.get(yapo,'_id',"No Hay id asociada")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                    <b>Url al post</b>
                  </TableCell>
                  <TableCell align="left">
                    <a href={_.get(yapo,'url',"No Hay url asociada")}>{_.get(yapo,'url',"No Hay url asociada")}</a>
                  </TableCell>
                </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Imagen de la Máquina</b>
                      </TableCell>
                      <TableCell align="left">
                          <img src={_.get(yapo,'img_url',"No Hay url asociada")} alt={"No hay imagen asociada"} height="240" width="320"></img>
                      </TableCell>
                  </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                  <b>Queries</b>
                  </TableCell>
                  <TableCell>
                {_.get(yapo, 'queries', []).map((query) => (
                    <p>{query}</p>
                ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                    <b>Nombre de la Categoría</b>
                  </TableCell>
                  <TableCell align="left">
                    {_.get(yapo,'ad_category.name',"No Hay nombre asociado")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                    <b>Url de la categoría</b>
                  </TableCell>
                  <TableCell align="left">
                    <a href={_.get(yapo,'ad_category.url',"No Hay url asociada")}>{_.get(yapo,'ad_category.url',"No Hay url asociada")}</a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                    <b>Fecha y Hora</b>
                  </TableCell>
                  <TableCell align="left">
                    {_.get(yapo,'date_time',"No hay fecha ni hora asociada")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                    <b>Descripción</b>
                  </TableCell>
                  <TableCell align="left">
                    {_.get(yapo,'description',"No hay descripción asociada")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                    <b>Tipo</b>
                  </TableCell>
                  <TableCell align="left">
                    {_.get(yapo,'details.Tipo',"No hay tipo asociado")}
                  </TableCell>
                </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Región</b>
                      </TableCell>
                      <TableCell align="left">
                          <p><b>Id: </b> {_.get(yapo,'region.id',"No hay id asociada")}</p>
                          <p><b>Nombre: </b>{_.get(yapo,'region.name',"No hay nombre asociado")}</p>
                          <p><b>Código ISO: </b>{_.get(yapo,'region.ISO',"No hay código asociado")}</p>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Localidad</b>
                      </TableCell>
                      <TableCell align="left">
                          <p><b>Id: </b> {_.get(yapo,'locality.id',"No hay id asociada")}</p>
                          <p><b>Nombre: </b>{_.get(yapo,'locality.name',"No hay nombre asociado")}</p>
                          <p><b>Region: </b>{_.get(yapo,'locality.region',"No hay region asociada")}</p>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Título del aviso</b>
                      </TableCell>
                      <TableCell align="left">
                          <p>{_.get(yapo,'title',"No hay id asociada")}</p>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Id del usuario</b>
                      </TableCell>
                      <TableCell align="left">
                          <p>{_.get(yapo,'yapo_user_id',"No hay id asociada")}</p>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Tags</b>
                      </TableCell>
                      <TableCell align="left">
                          {_.get(yapo, 'tags', []).map((query) => (
                              <p>{query}</p>
                          ))}
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                          <b>Categories</b>
                      </TableCell>
                      <TableCell align="left">
                          {categories.map(query =>(
                              <p>{
                                  _.get(query, 'name', "Nombre no encontrado")
                              }</p>
                          ))}
                      </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
    )
  };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

const enhance = compose(
  withStyles(DenseTable.useStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withDataProvider,
);
export default enhance(DenseTable);
