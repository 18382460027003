import React, {cloneElement, Fragment} from 'react';
import {
  CreateButton,
  ExportButton,
  Datagrid,
  DateField,
  TextField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  BooleanField,
  Edit,
  SelectInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  BooleanInput,
  Filter,
  AutocompleteInput,
  SelectArrayInput,
  ReferenceArrayInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  Create,
  FormDataConsumer,
  TabbedForm,
  FormTab,
  CardActions,
  CloneButton,
  FunctionField, Pagination,
} from 'react-admin';
import {communes, regions} from "../utils";
import BookIcon from '@material-ui/icons/List';
import {PricingTable} from "./machines";
import * as _ from 'lodash';
import AttributesForm from "./assets/AttributesForm";
import AccessoriesForm from "./assets/AccessoriesForm";
import AssetsCategoryFilters from "./assets/AssetsCategoryFilters";
import CustomList from '../libs/CustomList';
import ServicesForm from "./assets/ServicesForm";
import ClearFilterButton from "./assets/ClearFilterButton";
import ProviderDataTable from "./assets/ProviderDataTable";
import YapoDataTable from "./assets/YapoDataTable";
import AddAssetsToQuoteButton from "./assets/AddAssetsToQuoteButton";
import {BulkDeleteButton} from 'react-admin';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const AssetPagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />

const AssetBulkActionButtons = props => (
    <Fragment>
      <AddAssetsToQuoteButton label="Reset Views" {...props} />
      <BulkDeleteButton {...props} undoable={false}/>
    </Fragment>
);

const AssetFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      filterToQuery={searchText => ({$or: [{rut: {"$regex": searchText}}, {name: {"$regex": searchText}}]})}
      source="provider" reference="providers" allowEmpty label="Provider" alwaysOn>
      <AutocompleteInput optionText="name" placeholder="RUT or name"/>
    </ReferenceInput>
    <SelectInput label="Region" source="regions.id" choices={regions} allowEmpty alwaysOn/>
    <ReferenceInput source="category" reference="categories" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
      <SelectInput label="classificator1" optionText="name"/>
    </ReferenceInput>
    <NumberInput source="minimum_lease.$lte" label="minimum lease" alwaysOn/>
    <ReferenceArrayInput reference="services" source="$and.0.$and" label="services" alwaysOn
                         parse={c => (c && c.length > 0) ? c.map(cId => ({services: {$elemMatch: {available: true, _id: cId}}})) : [{}]}
                         format={c => c && c.map(d => _.get(d, 'services.$elemMatch._id')).filter(c => !!c)}>
      <SelectArrayInput>
        <ChipField source="name"/>
      </SelectArrayInput>
    </ReferenceArrayInput>
    <AutocompleteInput choices={communes} optionText="name" optionValue="id" source="commune.id" label="commune"
                       format={c => _.get(c, 'id')}/>
    <BooleanInput source="active"/>
    <NumberInput source="pricing.ranges.hourly_price.$lte" label="hourly price" alwaysOn/>
    <NumberInput label="Score" source="score.$gte" alwaysOn/>
    <AssetsCategoryFilters alwaysOn allowEmpty {...props}/>
  </Filter>
);

export const AssetIcon = BookIcon;

export const AssetList = props => (
  <CustomList actions={<ClearFilter/>} filters={<AssetFilter/>} sort={{ field: '_updated', order: 'DESC' }} {...props}
              bulkActionButtons={<AssetBulkActionButtons/>} pagination={<AssetPagination/>} perPage={50}
              filterDefaultValues={{embedded: {"provider": 1}}}>
    <Datagrid rowClick="edit">
      <ReferenceField source="category" reference="categories"><TextField source="name"/></ReferenceField>
      <FunctionField label="Provider" render={
        record => record && record.provider ?
          <a style={{zIndex: 9999}} href={`/#/providers/${_.get(record, 'provider._id')}`}
             onClick={event => event.stopPropagation()}>
            {_.get(record, 'provider.name', '')}
          </a> : null
      }/>
      <ReferenceField label="User" reference="users" source="provider.firebase_uid" allowEmpty><TextField source="first_name"/></ReferenceField>
      <FunctionField label="Brands" render={
        record => {
          if(record.brand){
          return (<ReferenceField record={{brand:record.brand}} {...props} source="brand" reference="brands"><TextField source="name"/></ReferenceField>);
          }
          else{
            return (<TextField record={record} source="brand_name"/>);
          }
        }
      }/>
      <FunctionField label="Model Name" render={
        record => {
          if(record.model){
            return (<ReferenceField record={{model:record.model}} {...props} source="model" reference="models"><TextField source="name"/></ReferenceField>);
          }
          else{
            return (<TextField record={record} source="model_name"/>);
          }
        }
      }/>
      <TextField label="Capacity" source="attributes.capacity"/>
      <NumberField source="units"/>
      <NumberField source="score"/>
      <FunctionField label="Services" render={
        record => {
          return (
            <ArrayField record={{services: _.get(record, 'services', []).filter(s => s.available)}} {...props} source="services">
              <SingleFieldList>
                <ReferenceField source="_id" reference="services"><ChipField source="name"/></ReferenceField>
              </SingleFieldList>
            </ArrayField>);
        }
      }/>
      <BooleanField source="active"/>
      <NumberField source="year"/>
      <DateField source="_created"/>
      <NumberField source="pricing.ranges.0.hourly_price" sortBy="pricing.ranges.hourly_price" label="Price"/>
      <ArrayField source="regions">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </CustomList>
);

const ClearFilter = ({basePath, className, data, filters, resource, showFilter, displayedFilters, filterValues, total,
                       currentSort, permanentFilter, exporter, maxResults, ...rest}) => (
  <CardActions className={className} {...rest}>
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    <CreateButton basePath={basePath} />
    <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
    />
    <ClearFilterButton />
  </CardActions>
);

const EditActions = ({basePath, className, data, ...rest}) => (
  <CardActions className={className} {...rest}>
    <CloneButton className="button-clone" basePath={basePath}
                 record={_.omit(data, ['iid', '_id', '_created', '_updated'])}/>
  </CardActions>
);
export const AssetEdit = props =>{
  const [tab, setTab] = React.useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  return (
  <Edit undoable={false} actions={<EditActions/>} {...props}>
    <TabbedForm>
      <FormTab label="general">
        <ReferenceInput source="category" reference="categories" allowEmpty
                        filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="provider" reference="providers" allowEmpty
                        filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <FormDataConsumer>
          {({formData}) =>
            !_.get(formData, 'category') && (<TextInput source="category_name" label="category_name"/>)
          }
        </FormDataConsumer>
        <ReferenceInput source="brand" reference="brands" allowEmpty
                        filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <FormDataConsumer>
          {({formData}) =>
            !_.get(formData, 'brand') && (<TextInput source="brand_name" label="brand_name"/>)
          }
        </FormDataConsumer>
        <ReferenceInput source="model" reference="models" allowEmpty
                        filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <FormDataConsumer>
          {({formData}) =>
            !_.get(formData, 'model') && (<TextInput source="model_name" label="model_name"/>)
          }
        </FormDataConsumer>
        <NumberInput source="units"/>
        <NumberInput source="year"/>
        <ServicesForm source="services" fullWidth/>
        <AutocompleteInput choices={communes} optionText="name" optionValue="id" source="commune"
                           format={c => _.get(c, 'id')}
                           parse={cId => _.find(communes, ({id}) => id === cId)}/>
        <SelectArrayInput choices={regions} optionText="name" optionValue="id" source="regions"
                          format={v => v && v.map(r => r.id)}
                          parse={r_ids => regions.filter(r => r_ids.includes(r.id))}/>
        <BooleanInput source="active"/>
        <NumberInput source="minimum_lease" label="Arriendo mínimo (horas)"/>
        <TextInput source="insurance" label="insurance"/>
        <TextInput source="payment_method" label="payment_method"/>
        <TextInput source="comments" label="comments" fullWidth/>
        <ArrayInput source="pricing.ranges" fullWidth>
          <SimpleFormIterator>
            <PricingTable/>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Attributes">
        <FormDataConsumer>
          {formDataProps => <AttributesForm fullWidth {...formDataProps}/>}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Accessories">
        <FormDataConsumer>
          {formDataProps => <AccessoriesForm fullWidth {...formDataProps}/>}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Provider">
        <ProviderDataTable fullWidth/>
      </FormTab>
      <FormTab label="Data Sources">
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (!(formData && formData.data_sources))
              return <Fragment>No hay datos para mostrar</Fragment>;
            const {data_sources} = formData;
            return (
              <Fragment>
                <AppBar position="static">
                  <Tabs value={tab} onChange={(event, selectedTab) => setTab(selectedTab)} {...rest}>
                    {
                      Object.keys(data_sources).map((value, index) =>
                        <Tab label={value === ' ' ? `n° ${index + 1}` : value} {...a11yProps(index)} />)
                    }
                  </Tabs>
                </AppBar>
                {
                  Object.keys(data_sources)[tab] === "yapo" ? (<YapoDataTable fullWidth {...formData}/>) : null
                }
              </Fragment>
            )
          }}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);
}

export const AssetCreate = props => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <ReferenceInput source="category" reference="categories" allowEmpty
                      filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput source="provider" reference="providers" allowEmpty
                      filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput source="brand" reference="brands" allowEmpty
                      filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput source="model" reference="models" allowEmpty
                      filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <NumberInput source="units"/>
      <NumberInput source="year"/>
      <ServicesForm source="services" fullWidth/>
      <SelectArrayInput choices={regions} optionText="name" optionValue="id" source="regions"
                        format={v => v && v.map(r => r.id)} parse={r_ids => regions.filter(r => r_ids.includes(r.id))}/>
      <AutocompleteInput choices={communes} optionText="name" optionValue="id" source="commune"
                         format={c => _.get(c, 'id')}
                         parse={cId => _.find(communes, ({id}) => id === cId)}/>
      <BooleanInput source="active"/>
      <NumberInput source="minimum_lease" label="Arriendo mínimo (horas)"/>
      <TextInput source="insurance" label="insurance"/>
      <TextInput source="payment_method" label="payment_method"/>
      <TextInput source="comments" label="comments" fullWidth/>
      <ArrayInput source="pricing.ranges" fullWidth>
        <SimpleFormIterator>
          <PricingTable/>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
