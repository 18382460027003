import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  TextInput,
  SimpleForm,
  Create,
} from 'react-admin';
import BookIcon from '@material-ui/icons/PlaylistAdd';

export const ServiceIcon = BookIcon;

export const ServiceList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="iid" label="#"/>
      <TextField source="name"/>
      <TextField source="desc"/>
    </Datagrid>
  </List>
);

export const ServiceEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="desc" />
    </SimpleForm>
  </Edit>
);

export const ServiceCreate = props => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="desc" />
    </SimpleForm>
  </Create>
);
