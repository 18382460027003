import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  UPDATE,
} from 'react-admin';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import * as _ from 'lodash';
import compose from 'recompose/compose';
import {withDataProvider} from 'react-admin';

class GenerateQuotationButton extends Component {
  state = {
    showDialog: false
  };

  handleClick = event => {
    const {fetchStart, fetchEnd, showNotification, dataProvider} = this.props;
    fetchStart();
    dataProvider(UPDATE, 'quotes', {
      data: {},
      id: this.props.record._id,
      subpath: 'generate_quotation'
    })
      .then(({data}) => {
        showNotification('La cotización se está generando...', 'success');
      })
      .catch(error => {
        const issues = _.get(error, 'body._issues', {});
        showNotification('Error: ' + Object.keys(issues).map(key => `${key} ${issues[key]}`).join(', ') + '.', 'error');
      })
      .finally(() => {
        this.setState({showDialog: false});
        fetchEnd();
      });
    event.stopPropagation();
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  handleSaveClick = () => {
  };

  render() {
    return (
      <Button onClick={this.handleClick}>
        <RestorePageIcon/>
      </Button>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDataProvider
)(GenerateQuotationButton);
