import React, {Component, Fragment} from 'react';
import GetApp from '@material-ui/icons/GetApp';
import firebase from 'firebase';
import * as _ from 'lodash';
import {Button} from 'react-admin';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default class DownloadQuoteButton extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null})
  };

  render() {
    const quote = this.props.record;
    const firebaseProjectId = _.get(firebase.app(), "options.projectId");
    const link = format => `https://firebasestorage.googleapis.com/v0/b/${firebaseProjectId}.appspot.com/o/quotes%2F${quote.id}%2Fquotation.${format}?alt=media`;
    return (
      <Fragment>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
          <GetApp/>
        </Button>
        <Menu id="simple-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}>
          <a href={link('pdf')} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} download>
            <MenuItem onClick={this.handleClose}>PDF</MenuItem>
          </a>
          <a href={link('html')} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} download>
            <MenuItem onClick={this.handleClose}>HTML</MenuItem>
          </a>
        </Menu>
      </Fragment>
    );
  }
}
