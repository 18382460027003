import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {translate} from 'react-admin';

const style = theme => ({
  root: {
    flex: 1,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
});

const PendingOrders = ({orders = [], customers = {}, translate, classes}) => (
  <Card className={classes.root}>
    <CardHeader title={translate('pos.dashboard.pending_orders')}/>
    <List dense={true}>
      {orders.map(record => (
        <ListItem
          key={record.id}
          button
          component={Link}
          to={`/providers`}>
          <ListItemText
            primary={record._id}
          />
          <ListItemSecondaryAction>
            <span className={classes.cost}>{record.count}</span>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  </Card>
);

const enhance = compose(
  withStyles(style),
  translate
);

export default enhance(PendingOrders);
