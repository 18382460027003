import React, {Fragment, useState} from 'react';
import {
  Button,
  SimpleForm,
  UPDATE,
  ReferenceInput,
  AutocompleteInput,
  useNotify,
  useRedirect,
  useUnselectAll,
} from 'react-admin';
import compose from 'recompose/compose';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import IconCheck from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {withStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import * as _ from 'lodash';
import {withDataProvider} from 'react-admin';
import {Form} from "react-final-form";

function AddAssetsToQuoteButton(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {dataProvider} = props;
  const notify = useNotify();
  const redirect = useRedirect();
  const unselectAll = useUnselectAll();

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);
  const onSubmit = values => {
    const candidate_assets = props.selectedIds;
    setIsLoading(true);
    dataProvider(UPDATE, 'quotes', {
      data: {candidate_assets: candidate_assets.map(_id => ({_id}))},
      id: values.quote,
      subpath: 'add_assets'
    })
      .then(({data}) => {
        setShowDialog(false);
        notify('Assets were added successfully', 'success');
        redirect(`/quotes/${data.id}`);
        unselectAll('assets');
      })
      .catch(error => notify(error.message, 'warning'))
      .finally(() => setIsLoading(false));
  };

  return (
    <Form onSubmit={onSubmit} render={({form}) => (
      <Fragment>
        <Button onClick={handleClick} label="assign to quote">
          <IconContentAdd/>
        </Button>
        <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add candidates to quote">
          <DialogTitle>Add candidates to quote</DialogTitle>
          <DialogContent>
            <SimpleForm onSubmit={onSubmit} toolbar={false}>
              <ReferenceInput source="quote" reference="quotes" allowEmpty
                              onChange={v => form.change('quote', v)}
                              filterToQuery={searchText => searchText ? ({id: parseInt(searchText)}) : null}>
                <AutocompleteInput
                  optionText={source => source.iid ? `(${source.iid}) ${_.get(source, 'client.name')}` : ''}
                  options={{suggestionsContainerProps: {className: props.classes.suggestionsContainerOpen}}}/>
              </ReferenceInput>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button saving={isLoading} onClick={form.submit} label="ra.action.save">
              <IconCheck/>
            </Button>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )}
    />
  );
}

const styles = function (theme) {
  return createStyles({
    suggestionsContainerOpen: {
      position: 'absolute',
      marginBottom: theme.spacing.unit * 3,
      zIndex: 1500,
    },
  });
};
export default compose(
  withDataProvider,
  withStyles(styles),
)(AddAssetsToQuoteButton);
