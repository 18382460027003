import React, {Fragment} from 'react';
import * as _ from 'lodash';
import Script from 'react-load-script';
import {TextInput} from 'react-admin';
import {useForm} from 'react-final-form';

export default function AddressAutocomplete(props) {
  const form = useForm();
  const {change} = form;

  const handlePlaceSelect = (autocomplete) => {
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;
    const location = _.get(addressObject, 'geometry.location');
    if (address) {
      change('client.address', addressObject.formatted_address);
      change('client.place', addressObject);
      change('client.location', {type: 'Point', coordinates: [location.lat(), location.lng()]});
    }
  };

  const handleScriptLoad = () => {
    const options = {
      types: ['address'],
      componentRestrictions: {country: 'cl'},
    };
    /*global google*/ // To disable any eslint 'google not defined' errors
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );
    // Avoid paying for data that you don't need by restricting the set of place fields that are returned
    // autocomplete.setFields(['address_components', 'formatted_address']);
    autocomplete.addListener('place_changed', () => handlePlaceSelect(autocomplete));
  };

  const key = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  return (
    <Fragment>
      <Script url={`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`} onLoad={handleScriptLoad}/>
      <TextInput country="cl" inputProps={{'id': 'autocomplete'}} {...props}/>
    </Fragment>
  );
}
