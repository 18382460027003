import React, {useEffect, useState} from "react";
import {
  FormTab,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput, useNotify,
} from 'react-admin';
import compose from 'recompose/compose';
import * as _ from 'lodash';
import {GET_ONE} from "../../ra-data-eve/src/actions";
import {PricingTable} from "../machines";
import {withDataProvider} from 'react-admin';
import {useForm} from "react-final-form";

function AccessoriesForm(props) {
  const form = useForm();
  const {change} = form;
  const {record, formData, dataProvider} = props;
  const notify = useNotify();
  const [category, setCategory] = useState(null);

  useEffect(() => {
    if (formData.category && formData.category !== _.get(category, '_id')) {
      const params = {filter: {}, id: formData.category};
      dataProvider(GET_ONE, 'categories', params)
        .then(({data}) => setCategory(data))
        .catch(error => notify(error.message, 'warning'))
    }
  }, [category, dataProvider, formData.category, notify]);

  useEffect(() => {
    if (category) {
      let accessories = _.get(category, 'accessories', []);
      if (record.category === category._id) {
        const rAccessories = _.map((record.accessories || []), ['id', 'name']);
        accessories = [...rAccessories, ...accessories.filter((acc) => !_.map(rAccessories, 'id').includes(acc.id))];
      }
      change('accessories', accessories);
    }
  }, [category, change, record.accessories, record.category]);

  return (
    <ArrayInput {...props} source="accessories" fullWidth>
      <SimpleFormIterator>
        <TextInput source="id"/>
        <BooleanInput source="available"/>
        <ArrayInput source="pricing.ranges" fullWidth>
          <SimpleFormIterator>
            <PricingTable/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="pricing.base"/>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const enhance = compose(
  withDataProvider,
);
AccessoriesForm.defaultProps = FormTab.defaultProps;
export default enhance(AccessoriesForm);
