import React from 'react';
import {
  List,
  Datagrid,
  ArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  Edit,
  NumberInput,
  TextInput,
  SimpleForm, SimpleFormIterator, BooleanInput, ArrayInput,
} from 'react-admin';
import BookIcon from '@material-ui/icons/Settings';
import RichTextInput from "ra-input-rich-text";

export const SettingIcon = BookIcon;

export const SettingList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <NumberField source="pricing.transfer.base_fare"/>
      <ArrayField source="quotation_receipt_copyright.statements">
        <SingleFieldList>
          <ChipField source="text"/>
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export const SettingEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm redirect="edit">
      <RichTextInput source="quotation_receipt_copyright" />
      <RichTextInput source="provider_request_message_template" />
      <NumberInput source="pricing.transfer.base_fare"/>
      <TextInput source="phone_number"/>
      <TextInput source="address_line_1"/>
      <TextInput source="address_line_2"/>
      <TextInput source="title"/>
      <TextInput source="subtitle"/>

      <br>
      </br>
      <ArrayInput source="payment_methods">
        <SimpleFormIterator >
          <TextInput source="id"/>
          <TextInput source="name"/>
          <BooleanInput source="default"/>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  </Edit>
);
