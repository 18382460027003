import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  Button,
  fetchEnd,
  fetchStart,
  showNotification,
} from 'react-admin';
import SearchIcon from '@material-ui/icons/Search';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/es';
import {regions} from "../../utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

moment.locale('es');

class FilterAssetsButton extends Component {
  constructor() {
    super();
    this.handleChangeRegion = this.handleChangeRegion.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeService = this.handleChangeService.bind(this);
    this.handleChangeAttribute = this.handleChangeAttribute.bind(this);
    this.handleChangeAccesory = this.handleChangeAccesory.bind(this);
  }
  state = {
    showDialog: false,
    check_region: false,
    check_category: false,
    check_service : false,
    check_attribute : false,
    check_accesory : false
  };

  handleChangeRegion(check_region) {
    this.setState({check_region: !this.state.check_region});
  }
  handleChangeCategory(check_category) {
    this.setState({check_category: !this.state.check_category});
  }
  handleChangeService(check_service) {
    this.setState({check_service: !this.state.check_service});
  }
  handleChangeAttribute(check_attribute) {
    this.setState({check_attribute: !this.state.check_attribute});
  }
  handleChangeAccesory(check_accesory) {
    this.setState({check_accesory: !this.state.check_accesory});
  }


  get_filter(){
    const quote = this.props.record;
    var categories = [];
    var accessories = [];
    var attributes;
    var services = [];

    // needs to verify if get 1
    if (_.get(quote, 'machines', []).length < 2){
      categories.push(_.get(quote, 'machines[0].category', []));
    }
    else{
      _.get(quote, 'machines', []).forEach(machine => categories.push(machine.category));
    }

    attributes = _.get(quote, 'attributes');

    _.get(quote, 'accessories', []).forEach(accessory => accessories.push(accessory.id));

    _.get(quote, 'services', []).forEach(service => services.push(service));

    let region = _.find(_.get(quote, 'client.place.address_components', []),
      ({types}) => types[0] === "administrative_area_level_1");

    let id_region = -1;
    if (region !== undefined) { //because asyncronous communication leads to undefined at first
      let region_name = region["long_name"]
        .replace("Región de", "").replace("Región del", "").replace("y la", "y de la").replace("Aysén", "Aisén").replace("Bío Bío", "Biobío");
      regions.forEach(r => {
        var strRegEx = '(\\w*'+region_name+'\\w*)';
        if (r["name"].match(strRegEx)){
          id_region = r["id"];
        }
      });
    }
    var json = {};

    if (this.state.check_region){
      json["regions"] = {"id":id_region};
    }
    if (this.state.check_category) {
      json["category"] = categories[0];
    }

    if (services.length > 0 && this.state.check_service){
      json["quote_filter"] = true;
      var services_json = [];
      services.forEach(id => services_json.push({"services":{"$elemMatch":{"available":true,"_id":id}}}));
      json["$and"] = [];
      let dic = {};
      dic["$and"] = services_json;
      json["$and"].push(dic);
    }

    if (accessories.length > 0 && this.state.check_accesory){
      json["quote_filter"] = true;
      var accesories_json = [];
      accessories.forEach(id => accesories_json.push({"accessories":{"$elemMatch":{"id":id,"available":true}}}));
      if (!json["$and"]){
        json["$and"] = [];
      }
      let dic = {};
      dic["$and"] = accesories_json;
      json["$and"].push(dic);
    }


    if (attributes !== undefined && this.state.check_attribute){
      json["quote_filter"] = true;
      let dic = {};
      Object.keys(attributes).forEach(key => dic[key] = {"$gte":parseInt(attributes[key])});
      json["attributes"] = dic;
    }
    return json

  }

  encode_special_char(url){
    url = url.replace(":", "%3A")
    return url

  }

  handleClick = event => {
    this.setState({showDialog: true});
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  render() {
    const {showDialog} = this.state;
    const filter = this.get_filter();
    return (
      <Fragment>

        <Button onClick={this.handleClick}>
          <SearchIcon/>
        </Button>

        <Dialog  fullWidth open={showDialog}
                 onClick={event => event.stopPropagation()}>
          <DialogTitle>Assets Filter</DialogTitle>
          <DialogContent>
            Choose the filters:
            <br></br>
            <FormControlLabel
              control={
                <Switch onChange={this.handleChangeRegion} check_region={this.state.check_region} />
              }
              label="Region"
            />
            <FormControlLabel
              control={
                <Switch onChange={this.handleChangeCategory} check_category={this.state.check_category} />
              }
              label="Category"
            />
            <FormControlLabel
              control={
                <Switch onChange={this.handleChangeService} check_service={this.state.check_service} />
              }
              label="Service"
            />
            <FormControlLabel
              control={
                <Switch onChange={this.handleChangeAttribute} check_attribute={this.state.check_attribute} />
              }
              label="Attribute"
            />
            <FormControlLabel
              control={
                <Switch onChange={this.handleChangeAccesory} check_accesory={this.state.check_accesory} />
              }
              label="Accesories"
            />
          </DialogContent>
          <DialogActions>
            <Link
              role="button"
              to={{
              pathname: `/assets`,
              search:  this.encode_special_char(encodeURI(`filter=${JSON.stringify(filter)}&order=DESC&page=1&perPage=50&sort=_updated`)),
            }} onClick={event=>event.stopPropagation()}>
              <SearchIcon/>
              FILTER
            </Link>

            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <CancelIcon/>
            </Button>
          </DialogActions>
        </Dialog>

      </Fragment>

    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  FilterAssetsButton
);
