import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  UPDATE,
} from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import EmailIcon from '@material-ui/icons/Mail';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/es';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import compose from 'recompose/compose';
import {withDataProvider} from 'react-admin';

moment.locale('es');

class SendQuoteByEmailButton extends Component {

  state = {
    showDialog: false,
    showDialogUnavailable: false,
  };


  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
    event.stopPropagation();
  };

  handleOpenDialog = event => {
    this.setState({showDialog: true});
  };

  handleOpenDialogUnavailable = event => {
    this.setState({showDialogUnavailable: true});
  };


  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  handleCloseClickUnavailable = () => {
    this.setState({showDialogUnavailable: false});
  }

  handleClose = () => {
    this.setState({anchorEl: null})
  };

  handleCloseUnavailable  = () => {
    this.setState({anchorEl: null})
  };


  handleSaveClick = () => {
    const {fetchStart, fetchEnd, showNotification, dataProvider} = this.props;
    fetchStart();
    dataProvider(UPDATE, 'quotes', {
      data: {},
      id: this.props.record._id,
      subpath: 'email_quotation'
    })
      .then(({data}) => {
        showNotification('El email con el documento de cotización fue enviado.', 'success');
      })
      .catch(error => showNotification(error.message, 'warning'))
      .finally(() => {
        this.setState({showDialog: false});
        fetchEnd();
      });
  };

  handleSaveClickUnavailable  = () => {
    const {fetchStart, fetchEnd, showNotification, dataProvider} = this.props;
    fetchStart();
    dataProvider(UPDATE, 'quotes', {
      data: {},
      id: this.props.record._id,
      subpath: 'email_quote_unavailable'
    })
        .then(({data}) => {
          showNotification('El email con el documento de no disponibilidad fue enviado.', 'success');
        })
        .catch(error => showNotification(error.message, 'warning'))
        .finally(() => {
          this.setState({showDialogUnavailable: false});
          fetchEnd();
        });
  };

  render() {
    const {showDialog, showDialogUnavailable} = this.state;
    const quote = this.props.record;
    return (
      <Fragment>

        <Button onClick={this.handleClick}>
          <SendIcon/>
        </Button>
        <Menu id="simple-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}>
          <MenuItem onClick={this.handleOpenDialogUnavailable}>Enviar no disponibilidad </MenuItem>
          <MenuItem onClick={this.handleOpenDialog}>Enviar cotización </MenuItem>
        </Menu>

        <Dialog  fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="send email with quotation"
                onClick={event => event.stopPropagation()}>
          <DialogTitle>Enviar cotización</DialogTitle>
          <DialogContent>
            ¿Estás seguro de que quieres enviar el documento de cotización a {_.get(quote, 'client.email')}?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSaveClick} label="ra.action.send">
              <EmailIcon/>
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <CancelIcon/>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog  fullWidth open={showDialogUnavailable} onClose={this.handleCloseClickUnavailable} aria-label="send email with quotation"
                 onClick={event => event.stopPropagation()}>
          <DialogTitle>Enviar no disponibilidad</DialogTitle>
          <DialogContent>
            ¿Estás seguro de que quieres enviar el documento de no disponibilidad a {_.get(quote, 'client.email')}?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSaveClickUnavailable} label="ra.action.send">
              <EmailIcon/>
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseUnavailable}>
              <CancelIcon/>
            </Button>
          </DialogActions>
        </Dialog>

      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDataProvider
)(SendQuoteByEmailButton);
