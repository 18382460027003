import React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    Edit,
    TextInput,
    SimpleForm,
    SearchInput,
    Filter,
    Create, Pagination,
} from 'react-admin';
import BookIcon from '@material-ui/icons/Work';

export const BrandIcon = BookIcon;
const BrandPagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />


const BrandFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="Name" source="name.$regex" alwaysOn allowEmpty/>
  </Filter>
);

export const BrandList = props => (
  <List filters={<BrandFilter/>} {...props}
        pagination={<BrandPagination/>} perPage={50}>
    <Datagrid rowClick="edit">
      <TextField source="name"/>
      <DateField source="_updated"/>
    </Datagrid>
  </List>
);

export const BrandEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name"/>
    </SimpleForm>
  </Edit>
);

export const BrandCreate = props => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name"/>
    </SimpleForm>
  </Create>
);
