import {
  useNotify,
  withDataProvider,
} from "react-admin";
import {compose} from "redux";
import React, {useEffect, useState} from "react";
import {GET_LIST} from "../../ra-data-eve/src/actions";
import {useField} from "react-final-form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import {withStyles} from "@material-ui/core";
import * as _ from 'lodash';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '90%',
      width: 250,
    },
  },
};

function MilestonesInput(props) {
  const [milestones, setMilestones] = useState(null);
  const [milestoneOptions, setMilestoneOptions] = useState(null);
  const [record, setRecord] = useState(null);
  const {dataProvider, classes, reference} = props;
  const notify = useNotify();
  const selectedMilestonesField = useField('milestones');

  useEffect(() => {
    if (!milestones)
      dataProvider(GET_LIST, reference, {})
        .then(({data}) => setMilestones(_.keyBy(data, '_id')))
        .catch(error => notify(error.message, 'warning'));
  }, [dataProvider, milestones, notify, reference]);

  useEffect(() => {
    if (milestones && props.record && selectedMilestonesField.input.value && (!record || record._updated !== props.record._updated)) {
      setRecord(props.record);
      const recordMilestonesDict = _.keyBy(selectedMilestonesField.input.value, '_id');
      const _updated = (new Date()).toUTCString();
      setMilestoneOptions(_.map(milestones, ({_id}) => recordMilestonesDict[_id] || {_id, _updated}));
    }
  }, [milestones, props.record, record, selectedMilestonesField.input.value]);

  if (!milestoneOptions || !selectedMilestonesField.input.value) return null;

  return <FormControl className={classes.formControl}>
    <InputLabel id="demo-mutiple-checkbox-label">Milestones</InputLabel>
    <Select
      {...selectedMilestonesField.input}
      multiple
      input={<Input/>}
      renderValue={milestone => milestone.map(({_id}) => _.get(milestones, `${_id}.name`)).join(', ')}
      MenuProps={MenuProps}
    >
      {_.map(milestoneOptions, milestone => (
        <MenuItem key={milestone._id} value={milestone}>
          <Checkbox checked={_.map(selectedMilestonesField.input.value, '_id').indexOf(milestone._id) > -1}/>
          <ListItemText primary={milestones[milestone._id].name}/>
        </MenuItem>
      ))}
    </Select>
  </FormControl>;
}

export default compose(
  withStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 400,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })),
  withDataProvider,
)(MilestonesInput);
