import React, {Component} from 'react';
import Delete from '@material-ui/icons/Delete';
import * as _ from 'lodash';
import {
    Button,
    fetchEnd,
    showNotification,
    UPDATE,
} from 'react-admin';
import compose from 'recompose/compose';
import {withDataProvider} from 'react-admin';

class DeleteProviderButton extends Component {

    handleClick = event => {
        const {dataProvider, record, quote} = this.props;
        dataProvider(UPDATE, 'quotes', {
            data: {
                provider_id:record._id._id
            },
            id: quote.id,
            subpath:'delete_provider_from_quote'
        }, {
            onSuccess: {
                unselectAll: true,
            }}).then(({data}) => {
            // Update the main react-admin form (in this case, the comments creation form)
            showNotification('Provider deleted from quote', 'success');
            }).catch(error => {
                const issues = _.get(error, 'message._issues', {});
                showNotification(Object.keys(issues).map(key => `${key} ${issues[key]}`).join(', ') + '.', 'error');
            })
            .finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                fetchEnd();
            });
        window.location.reload(true);
    };

    render() {
        return (
                <Button label="Delete" onClick={this.handleClick}>
                    <Delete/>
                </Button>
        );
    }
}

export default compose(
  withDataProvider
)(DeleteProviderButton);
