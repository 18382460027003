import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import compose from 'recompose/compose';
import {createStyles} from "@material-ui/core";
import * as _ from 'lodash';
import {GET_ONE} from "../../ra-data-eve/src/actions";
import {
  Link,
  useNotify,
  withDataProvider,
} from 'react-admin';

function ProviderDataTable(props) {
  const [provider, setProvider] = useState(null);
  const {classes, dataProvider} = props;
  let providerId = _.get(props, 'record.provider');
  providerId = (typeof providerId) === "string" ? providerId : null;
  const notify = useNotify();

  useEffect(() => {
    if (providerId && (!provider || providerId !== provider._id))
      dataProvider(GET_ONE, 'providers', {id: providerId})
        .then(({data}) => setProvider(data))
        .catch(error => notify(error.message, 'warning'));
  }, [dataProvider, notify, provider, providerId]);

  useEffect(() => {
    if (props.provider && !_.isEqual(props.provider, provider))
      setProvider(props.provider);
  }, [props.provider, provider]);

  if (!provider)
    return (<p>No hay proveedor asociado</p>);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                <b>Nombre</b>
              </TableCell>
              <TableCell align="left">
                <Link to={{pathname: `/providers/${provider._id}`}}>
                  {provider.name}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                <b>RUT</b>
              </TableCell>
              <TableCell align="left">{_.get(provider, 'rut', (<i>No ingresado</i>))}</TableCell>
            </TableRow>
            {_.get(provider, 'contacts', []).map((contact, cIndex) => (
              <TableRow>
                <TableCell component="th" scope="row" style={{width: '50px !important'}}>
                  <b>Contacto #{cIndex + 1}</b>
                </TableCell>
                <TableCell align="left">
                  {contact.first_name} {contact.last_name}, {_.get(contact, 'phones', []).join(', ')}, {_.get(contact, 'emails', []).join(', ')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

const enhance = compose(
  withStyles((theme) => {
    return createStyles({
      root: {
        width: '500px',
      },
      paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
      table: {},
    })
  }),
  withDataProvider,
);
export default enhance(ProviderDataTable);
