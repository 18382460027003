import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import {
  FormTab,
  TextInput,
  NumberInput,
  BooleanInput,
  SelectInput, SelectArrayInput, useNotify
} from 'react-admin';
import compose from 'recompose/compose';
import * as _ from 'lodash';
import {GET_ONE} from "../../ra-data-eve/src/actions";
import {withDataProvider} from 'react-admin';
import {useForm} from "react-final-form";

const inputTypes = {
  decimal: NumberInput,
  integer: NumberInput,
  string: TextInput,
  bool: BooleanInput,
};
export const decideInput = type => inputTypes[type] || TextInput;

function AttributesForm(props) {
  const [category, setCategory] = useState(null);
  const form = useForm();
  const notify = useNotify();
  const {change} = form;
  const {record, formData, dataProvider} = props;
  const attributes = record.category === formData.category ?
    Object.keys(_.get(record, 'attributes', {})) :
    [];
  const categoryAttributes = _.get(category, 'attributes', []);
  const categoryAccesories = _.get(category, 'accessories', []);

  useEffect(() => {
    if (formData.category && formData.category !== _.get(category, '_id')) {
      dataProvider(GET_ONE, 'categories', {filter: {}, id: formData.category})
        .then(({data}) => setCategory(data))
        .catch(error => notify(error.message, 'warning'));
    }
  }, [category, dataProvider, formData.category, notify]);

  useEffect(() => {
    if (!formData.category || record.category !== formData.category) {
      change('attributes', {});
      change('accessories', []);
    }
  }, [change, formData.category, record.category]);

  const accessories_format = acc => {
    if (acc === undefined) {
      return;
    }
    var array = [];
    acc.forEach(({id}) => array.push(id));
    return (array)
  };
  const accessories_parse = array => {
    var acc = [];
    array.forEach(
      a => categoryAccesories.forEach(
        b => {
          if (b["id"] === a) acc.push({"id": b["id"], "name": b["name"]});
        }
      ));
    return (acc);
  };
  return (
    <Grid {...props}>

      {props.from_quote && categoryAccesories.length > 0 &&
      <SelectArrayInput source="accessories" format={accessories_format} fullWidth
                        parse={accessories_parse} label="Accesories"
                        choices={categoryAccesories} optionText="name" optionValue="id"/>
      }

      {attributes
        .map(attr => {
          const attribute = categoryAttributes.filter(a => a.id === attr);
          if (attribute[0] && attribute[0].options && attribute[0].options.length > 0) {
            const Input = decideInput(attribute.map(a => a.type)[0]);
            if (props.from_quote) {
              return (<SelectInput allowEmpty {...props} source={`attributes.${attr}`} label={attribute[0].label}
                                   optionText={"label"} optionValue={"value"} choices={attribute[0].options}/>)
            } else {
              return (<Input {...props} source={`attributes.${attr}`} label={attribute[0].name} optionText={"name"}
                             optionValue={"value"} choices={attribute[0].options}/>)
            }
          } else {
            const Input = decideInput(attribute.map(a => a.type)[0]);
            return (<Input {...props} source={`attributes.${attr}`} label={`attributes.${attr}`}/>);
          }
        })}
      {categoryAttributes
        .filter(attr => !attributes.includes(attr.id))
        .map(attr => {
          if (attr.options && attr.options.length > 0) {
            if (props.from_quote) {
              return (<SelectInput allowEmpty {...props} source={`attributes.${attr.id}`} label={attr.label}
                                   optionText={"label"}
                                   optionValue={"value"} choices={attr.options}/>)
            } else {
              return (
                <SelectInput {...props} source={`attributes.${attr.id}`} label={attr.name} optionText={"label"}
                             optionValue={"value"} choices={attr.options}/>)
            }
          } else {
            const Input = decideInput(attr.type);
            if (props.from_quote) {
              return (<Input {...props} source={`attributes.${attr.id}`} label={attr.label}/>)
            } else {
              return (<Input {...props} source={`attributes.${attr.id}`} label={attr.name}/>)
            }
          }
        })}
    </Grid>
  );
}

const enhance = compose(
  withDataProvider,
);
AttributesForm.defaultProps = FormTab.defaultProps;
export default enhance(AttributesForm);
