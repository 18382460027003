import React, { Component } from 'react';
import { GET_LIST, Responsive, withDataProvider } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PendingOrders from './PendingOrders';
import PendingReviews from './PendingReviews';
import NewCustomers from './NewCustomers';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

class Dashboard extends Component {
    state = {};

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        // handle refresh
        if (this.props.version !== prevProps.version) {
            this.fetchData();
        }
    }

    fetchData() {
        this.fetchOrders();
        this.fetchReviews();
        this.fetchCustomers();
    }

    async fetchOrders() {
        const { dataProvider } = this.props;
        const aMonthAgo = new Date();
        aMonthAgo.setDate(aMonthAgo.getDate() - 30);
        const { data: recentOrders } = await dataProvider(
            GET_LIST,
            'providers_per_region',
            {
                filter: {},
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const aggregations = recentOrders
            .reduce(
                (stats, order) => {
                    if (order.status !== 'cancelled') {
                        stats.revenue += order.total;
                        stats.nbNewOrders++;
                    }
                    stats.pendingOrders.push(order);
                    return stats;
                },
                {
                    revenue: 0,
                    nbNewOrders: 0,
                    pendingOrders: [],
                }
            );
        this.setState({
            revenue: aggregations.revenue.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }),
            nbNewOrders: aggregations.nbNewOrders,
            pendingOrders: aggregations.pendingOrders,
        });
    }

    async fetchReviews() {
        const { dataProvider } = this.props;
        const { data: reviews } = await dataProvider(GET_LIST, 'quotes', {
            filter: {
                'milestones.key': 'new'
            },
            embedded: {"category":1},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 10 },
        });
        const nbPendingReviews = reviews.reduce(nb => ++nb, 0);
        const pendingReviews = reviews.slice(0, Math.min(10, reviews.length));
        this.setState({ pendingReviews, nbPendingReviews });
    }

    async fetchCustomers() {
        const { dataProvider } = this.props;
        const aMonthAgo = new Date();
        aMonthAgo.setDate(aMonthAgo.getDate() - 30);
        const { data: newCustomers } = await dataProvider(
            GET_LIST,
            'users',
            {
                sort: { field: '_created', order: 'DESC' },
                pagination: { page: 1, perPage: 10 },
            }
        );
        this.setState({
            newCustomers,
            nbNewCustomers: newCustomers.reduce(nb => ++nb, 0),
        });
    }

    render() {
        const {
            nbNewCustomers,
            nbPendingReviews,
            newCustomers,
            pendingOrders,
            pendingOrdersCustomers,
            pendingReviews,
            pendingReviewsCustomers,
        } = this.state;
        return (
            <Responsive
                xsmall={
                    <div>
                        <div style={styles.flexColumn}>
                            <div style={styles.flex}>
                              <PendingReviews
                                nb={nbPendingReviews}
                                reviews={pendingReviews}
                                customers={pendingReviewsCustomers}
                              />
                              <NewCustomers
                                nb={nbNewCustomers}
                                visitors={newCustomers}
                              />
                            </div>
                            <div style={styles.singleCol}>
                                <PendingOrders
                                    orders={pendingOrders}
                                    customers={pendingOrdersCustomers}/>
                            </div>
                        </div>
                    </div>
                }
                small={
                    <div style={styles.flexColumn}>
                        <div style={styles.flex}>
                          <PendingReviews
                            nb={nbPendingReviews}
                            reviews={pendingReviews}
                            customers={pendingReviewsCustomers}
                          />
                          <NewCustomers
                            nb={nbNewCustomers}
                            visitors={newCustomers}
                          />
                        </div>
                        <div style={styles.singleCol}>
                            <PendingOrders
                                orders={pendingOrders}
                                customers={pendingOrdersCustomers}
                            />
                        </div>
                    </div>
                }
                medium={
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.flex}>
                              <div style={styles.flex}>
                                <PendingReviews
                                  nb={nbPendingReviews}
                                  reviews={pendingReviews}
                                  customers={pendingReviewsCustomers}
                                />
                                <NewCustomers
                                  nb={nbNewCustomers}
                                  visitors={newCustomers}
                                />
                              </div>
                            </div>
                        </div>
                        <div style={styles.rightCol}>
                          <div style={styles.singleCol}>
                            <PendingOrders
                              orders={pendingOrders}
                              customers={pendingOrdersCustomers}
                            />
                          </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    version: state.admin.ui.viewVersion,
});

export default compose(
    connect(mapStateToProps),
    withDataProvider
)(Dashboard);
