import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  Edit,
  TextInput,
  SelectInput,
  Create,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  SimpleForm,
  BooleanInput, Pagination,
} from 'react-admin';
import BookIcon from '@material-ui/icons/DirectionsBus';
import * as _ from "lodash";
import {decideInput} from "./assets/AttributesForm";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RichTextInput from "ra-input-rich-text";

const CategoriesPagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />

export const CategoryIcon = BookIcon;
export const attributeTypes = [
  {id: 'integer', name: 'Integer'}, {id: 'decimal', name: 'Decimal'}, {id: 'string', name: 'String'},
  {id: 'bool', name: 'Boolean'}
];

export const CategoryList = props => (
  <List {...props}
        agination={<CategoriesPagination/>} perPage={50}>
    <Datagrid rowClick="edit">
      <TextField source="name"/>
      <ImageField source="image" title="name"/>
    </Datagrid>
  </List>
);

export const CategoryEdit = props => {
  const [state, setState] = React.useState({});
  return (
    <Edit undoable={false} {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name"/>
          <TextInput source="image"/>
          <BooleanInput source="hidden"/>
        </FormTab>
        <FormTab label="attributes">
          <ArrayInput source="attributes">
            <SimpleFormIterator>
              <TextInput source="id"/>
              <TextInput source="name"/>
              <TextInput source="label"/>
              <BooleanInput source="hidden"/>
              <SelectInput source="type" choices={attributeTypes} allowEmpty alwaysOn/>
              <FormDataConsumer>
                {({formData, id, getSource}) => {
                  const hasOptions = !!state[id] || _.get(formData, `${id}.options`, []).length > 0;
                  const toggle = event => {
                    if (!event.target.checked) _.set(formData, `${id}.options`, []);
                    setState({...state, [id]: event.target.checked});
                  };
                  const type = _.get(formData, `${id}.type`);
                  if (!['integer', 'decimal', 'string'].includes(type))
                    return;
                  const Input = decideInput(type);
                  return (
                    <Grid>
                      <FormControlLabel control={<Switch checked={hasOptions} onChange={toggle} value={id}/>}
                                        label="Agregar opciones?"/>
                      {hasOptions &&
                      <ArrayInput source={getSource('options')}>
                        <SimpleFormIterator>
                          <Input source="value"/>
                          <TextInput source="label"/>
                        </SimpleFormIterator>
                      </ArrayInput>}
                    </Grid>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="accessories">
          <ArrayInput source="accessories">
            <SimpleFormIterator>
              <TextInput source="id"/>
              <TextInput source="name"/>
              <BooleanInput source="hidden"/>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Términos y Condiciones">
              <RichTextInput source="receipt_copyright"/>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const CategoryCreate = props => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput source="image"/>
      <BooleanInput source="hidden"/>
    </SimpleForm>
  </Create>
);
