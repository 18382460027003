import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Form} from 'react-final-form';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  UPDATE,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import compose from 'recompose/compose';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {withStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import * as _ from 'lodash';
import { push } from 'connected-react-router';
import {withDataProvider} from 'react-admin';

class AddProvidersToQuoteButton extends Component {
  state = {
    error: false,
    showDialog: false,
    quote: null,
  };

  handleClick = () => {
    this.setState({showDialog: true});
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  handleSubmit = values => {
    const {fetchStart, fetchEnd, showNotification, push, dataProvider, selectedIds} = this.props;
    const {quote} = this.state;
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(UPDATE, 'quotes', {
      data: {candidate_providers: selectedIds.map(_id => ({_id}))},
      id: quote,
      subpath: 'add_providers'
    }, {
        onSuccess: {
          unselectAll: true,
        }})
      .then(({data}) => {
        this.setState({showDialog: false});
        showNotification('Providers were added successfully', 'success');
        push(`/quotes/${data.id}`);
      })
      .catch(error => {
        const issues = _.get(error, 'message._issues', {});
        showNotification(Object.keys(issues).map(key => `${key} ${issues[key]}`).join(', ') + '.', 'error');
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const {showDialog} = this.state;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="ra.action.create">
          <IconContentAdd/>
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Add candidates to quote">
          <DialogTitle>Add candidates to quote</DialogTitle>
          <DialogContent>
            <Form
              onSubmit={this.handleSubmit}
              render={({handleSubmit}) => (
                <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick}
                        aria-label="Add candidates to quote">
                  <DialogTitle>Add candidates to quote</DialogTitle>
                  <DialogContent>
                    <SimpleForm onSubmit={handleSubmit} toolbar={false}>
                      <ReferenceInput source="quote" reference="quotes" allowEmpty
                                      onChange={quote => this.setState({quote})}
                                      filterToQuery={searchText => searchText && ({id: parseInt(searchText)})}>
                        <RAutocompleteInput
                          optionText={source => `(${source.iid}) ${source.client && source.client.name}`}/>
                      </ReferenceInput>
                    </SimpleForm>
                  </DialogContent>
                  <DialogActions>
                    <SaveButton handleSubmitWithRedirect={handleSubmit}/>
                    <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                      <IconCancel/>
                    </Button>
                  </DialogActions>
                </Dialog>
              )}/>
          </DialogContent>
          <DialogActions>
            <SaveButton handleSubmitWithRedirect={this.handleSaveClick}/>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  push
};

const styles = function (theme) {
  return createStyles({
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    root: {},
    suggestionsContainerOpen: {
      position: 'absolute',
      marginBottom: theme.spacing.unit * 3,
      zIndex: 1500,
    },
    suggestionsPaper: {
      maxHeight: '50vh',
      overflowY: 'auto',
    },
    suggestion: {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
    },
    suggestionText: {fontWeight: 300},
    highlightedSuggestionText: {fontWeight: 500},
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    chip: {
      marginRight: theme.spacing.unit,
      marginTop: theme.spacing.unit,
    },
    chipDisabled: {
      pointerEvents: 'none',
    },
    chipFocused: {
      backgroundColor: blue[300],
    },
  });
};

const RAutocompleteInput = compose(withStyles(styles))(AutocompleteInput);
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDataProvider
)(AddProvidersToQuoteButton);
