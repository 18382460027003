import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {
  Button,
  fetchEnd,
  fetchStart,
  showNotification,
  UPDATE,
} from 'react-admin';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import * as moment from 'moment';
import 'moment/locale/es';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';
import * as _ from "lodash";
import {GET_ONE} from "../../ra-data-eve/src/actions";
import compose from 'recompose/compose';
import {withDataProvider} from 'react-admin';

moment.locale('es');

class OfficialProvidersButton extends Component {
  constructor(props) {
    super(props);
    this.state = {showDialog: false, provider: {}, providerId : this.props.record._id["_id"], providerOfficialState : false};
  }

  componentDidMount() {
    this.state.providerId && this.loadProvider(this.state.providerId);
    this.obtainOfficialStatus(this.state.providerId);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.obtainOfficialStatus(this.state.providerId);
    }
  }

  loadProvider(providerId) {
    const {fetchStart, fetchEnd, dataProvider} = this.props;
    const params = {
      filter: {},
      id: providerId
    };
    fetchStart();
    dataProvider(GET_ONE, 'providers', params)
      .then(({data}) => {
        this.setState({provider: data});
      })
      .catch(error => {
        const issues = _.get(error, 'body._issues', {});
        this.props.showNotification(Object.keys(issues).map(key => `${key} ${issues[key]}`).join(', ') + '.', 'error');
      })
      .finally(() => fetchEnd());
  }

  obtainOfficialStatus(providerId){
    // eslint-disable-next-line no-unused-vars
    for (const candidate of _.get(this.props.quote, 'candidate_providers', [])) {
      if (_.get(candidate, '_id._id', '') === providerId) {
        this.setState({providerOfficialState: _.get(candidate, 'official', false)});
        return;
      }
    }
  }

  handleClick = event => {
    this.setState({showDialog: true});
    event.stopPropagation();
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  setProviderOfficial = (official) => {
    const {dataProvider} = this.props;
    dataProvider(UPDATE, 'quotes', {
      data: {
        provider_id: this.state.providerId,
        official: official
      },
      id: this.props.quote.id,
      subpath: 'make_official_provider'
    })
      .then(({data}) => {
        this.setState({providerOfficialState: official});
        const message = `El proveedor ha sido ${official ? 'asignado' : 'removido'} como oficial.`;
        this.props.showNotification(message, 'success');
      })
      .catch(error => {
        const issues = _.get(error, 'message._issues', {});
        this.props.showNotification(Object.keys(issues).map(key => `${key} ${issues[key]}`).join(', ') + '.', 'error');
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
        this.handleCloseClick();
      });
  };


  render() {
    const isOfficial = this.state.providerOfficialState;
    return (
      <Fragment>
        <Tooltip title="Add/Remove Official" aria-label="add_remove">
          <Button onClick={this.handleClick} label={isOfficial ? "Si" : "No"}>
            {isOfficial ? (<StarIcon/>) : (<StarBorderIcon/>)}
          </Button>
        </Tooltip>
        <Dialog  fullWidth open={this.state.showDialog}  onClose={this.handleCloseClick} aria-label="official provider"
                 onClick={event => event.stopPropagation()}>
          <DialogTitle>Official providers</DialogTitle>
          <DialogContent>
            {isOfficial ?
              (
                <div>
                  ¿Remover <b> {this.state.provider.name} </b> como proveedor oficial para esta cotización?
                </div>
              ) : (
                <div>
                  ¿Hacer <b> {this.state.provider.name} </b> proveedor oficial para esta cotización?
                </div>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button label="Confirmar" onClick={() => this.setProviderOfficial(!isOfficial)}>
              <CheckIcon/>
            </Button>
            <Button label="Cancelar" onClick={this.handleCloseClick}>
              <CloseIcon/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDataProvider
)(OfficialProvidersButton);
