import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Edit,
    SelectInput,
    TextInput,
    ReferenceInput,
    SimpleForm,
    SearchInput,
    AutocompleteInput,
    Filter,
    Create, Pagination,
} from 'react-admin';
import BookIcon from '@material-ui/icons/Book';

const ModelPagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />


export const ModelIcon = BookIcon;

const ModelFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="brand" reference="brands" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex":searchText}})} alwaysOn>
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
    <SearchInput label="Name" source="name.$regex" alwaysOn allowEmpty/>
  </Filter>
);

export const ModelList = props => (
  <List filters={<ModelFilter/>} {...props}
        pagination={<ModelPagination/>} perPage={50}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceField source="brand" reference="brands"><TextField source="name" /></ReferenceField>
    </Datagrid>
  </List>
);

export const ModelEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <ReferenceInput source="brand" reference="brands"><SelectInput optionText="name" /></ReferenceInput>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const ModelCreate = props => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <ReferenceInput source="brand" reference="brands" allowEmpty
                      filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
